import getMonth from 'date-fns/getMonth';
import getYear  from 'date-fns/getYear';

const terms = [ 'Fall', 'Winter', 'Spring', 'Summer' ];

function _quartersList (back, forward) {
  const year = getYear(new Date());

  let out = [];
  for (let i = year - back, l = year + forward; i <= l; i++) {
    out = [ ...out, ...terms.map(t => `${t} ${i}`) ];
  }

  return out;
}

function _quarter (skipTerms = 0, skipSummer = false) {
  const now   = new Date();
  const month = getMonth(now) + 1;
  const year  = getYear(now);
  // console.log(month)

  // this month over four is quarter
  // +1 because fall comes first
  // skip as many as we like
  // mod4 because there are four terms
  let termNum = (Math.floor(month / 4) + 1 + skipTerms) % 4;
  if (skipSummer) {
    // if no summer, skip it and move to fall
    termNum = termNum === 3 ? 0 : termNum;
  }

  const term = terms[termNum];

  return `${term} ${year}`;
}

export const quartersList = _quartersList(2, 5);
export const thisQuarter  = _quarter(0);
export const nextQuarter  = _quarter(1);


// console.log({quartersList,
//   thisQuarter,
//   nextQuarter})
