import { useQuery }                                                                     from '@apollo/client';
import { LinearProgress, ListItemButton, ListItemText, Pagination, TextField, Tooltip } from '@mui/material';
import Avatar                                                                           from '@mui/material/Avatar';
import Box                                                                              from '@mui/material/Box';
import Divider                                                                          from '@mui/material/Divider';
import Link                                                                             from '@mui/material/Link';
import List                                                                             from '@mui/material/List';
import Paper                                                                            from '@mui/material/Paper';
import { useEffect }                                                                    from 'react';
import usePagination                                                                    from '../lib/usePagination';
import { ErrorBlurb }                                                                   from '../other-pages/Error';
import { GQL_QUERY_LIST_FACULTY_W_CREDIT }                                              from './gql/queries';


export default function ListFaculty () {
  const { page, sort, filter, set }       = usePagination({
    page:             { limit: 10, page: 1 },
    sort:             { field: 'ucinetid', direction: 1 },
    filter:           {},
    syncSearchParams: true,
  });
  const { data, loading, error, refetch } = useQuery(GQL_QUERY_LIST_FACULTY_W_CREDIT, {
    variables: {
      page, sort, filter
    }
  });

  const setFilter = ({ target }) => {
    const val = target.value;
    set('filter', { text: val });
  }

  useEffect(() => {
    refetch({ page, sort, filter })
  }, [ page, sort, filter ])

  let body;
  if (error) {
    body = <ErrorBlurb err={error} />
  } else {
    body = <ListFacultyBody items={data ? data.listFaculty.items : []} />
  }
  const pagination = (
    <Box textAlign='center'>
      <Pagination
        count={data ? data.listFaculty.meta.pages : 3}
        page={page.page}
        sx={{ display: 'inline-block' }}
        onChange={(e, newPage) => set('page', { ...page, page: newPage })}
      />
    </Box>
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Box display='flex' alignItems='center'>
        <Box width={200}>
          <TextField
            fullWidth size="small"
            variant={'standard'}
            label="Filter"
            value={filter.text}
            onChange={setFilter}
          />
        </Box>
        <Box flexGrow={12}>
          {pagination}
        </Box>
        <Box width={200}>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />


      {loading ? <LinearProgress /> : <LinearProgressPlaceholder />}
      {body}

      {pagination}
    </Paper>
  );


}

function ListFacultyBody ({ items = [] }) {

  return (
    <List>
      {items.map(i => (
        <ListItemButton key={i.id} component={Link} href={`/faculty/${i.id}`}>
          <ListItemText
            primary={`${i.lastName}, ${i.firstName}`}
            secondary={i.ucinetid}
          />
          <Tooltip title={`${i.availableCredit} TIRF Credits available`} placement="left">
            <Avatar sx={{ bgcolor: i.availableCredit >= 1 ? 'success.main' : 'gray.500' }}>
              {i.availableCredit}
            </Avatar>
          </Tooltip>
        </ListItemButton>
      ))}
    </List>
  );
}

function LinearProgressPlaceholder () {
  return <Box height={4} />
}
