import { createContext, useContext, useEffect, useState }           from 'react';
import { useLocation, useNavigate, useSearchParams }                from 'react-router-dom';
import { apiServer, samlServer }                                    from '../../config';
import { getProfileQuery }                                          from '../../data/auth';
import noop                                                         from '../../utils/noop';
import { getFlash, resetFlash, setFlash }                           from './flash';
import { getToken, LOCALSTORAGE_TOKEN_FIELD, resetToken, setToken } from './token';



export const LOGIN_HREF      = `${samlServer}/login`
export const PROFILE_ADDRESS = `${apiServer}/profile`

export function throwAuthErrorEvent (message) {
  const e = new CustomEvent('AuthError', { detail: { message } });
  window.dispatchEvent(e);
}

export function AuthGateway ({ login, loading: loadingEl, children }) {
  const { isLoggedIn, loading } = useAuth();

  // console.log('authGateway', {
  //   isLoggedIn, loading,
  // })

  if (loading) {
    return loadingEl;
  }
  if (!isLoggedIn) {
    return login;
  }
  return children;

}


const AuthContext = createContext({
  loading:        true,
  isLoggedIn:     false,
  logout:         noop,
  hasPermissions: noop,
  token:          '',
  ucinetid:       '',
  user:           null,
  error:          null,
  tokenPayload:   null
});

export const useAuth = () => useContext(AuthContext);


export function AuthProvider ({ children, onLogout }) {
  const navigate                          = useNavigate();
  const location                          = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ loading, setLoading ]           = useState(true);
  const [ error, setError ]               = useState(false);
  const [ user, setUser ]                 = useState({ ucinetid: null, roles: [] });
  const [ tokenPayload, setTokenPayload ] = useState({});
  const token                             = getToken();

  const state = {
    loading:    loading,
    isLoggedIn: !!(user && user.ucinetid),
    ucinetid:   user && user.ucinetid,
    error,
    user,
    token,
    tokenPayload
  };



  const getProfile = async () => {
    try {
      // console.log('getting profile')
      const { data } = await getProfileQuery();
      // console.log('profile gotten', data)
      setUser(data.profile.user);
      setTokenPayload(data.profile.token);
    } catch (e) {
      console.warn('problem authenticating:', e.response);
      logout(e.response && e.response.data)
    }
  };

  const checkAuthError = () => {
    const redirectAuthError = searchParams.get('auth-error');

    if (redirectAuthError) {
      console.warn(`Detected Auth Error on return from login,`, redirectAuthError)
      setError(redirectAuthError)
      resetToken();
      setLoading(false);

      navigate('/', {
        replace: true,
        state:   {
          authErrorMessage: redirectAuthError === 'nonuser'
                            ? 'Your UCINetID has not been authorized for this service. If you think this is in error, please contact tech support'
                            : 'An Unknown error occurred while signing in. Please contact Rhett Lowe at rhett@uci.edu'
        }
      })

      return true;
    }

    return false;
  };

  const init = async () => {
    if (checkAuthError()) {
      return;
    }

    const token       = getToken();
    const searchToken = searchParams.get(LOCALSTORAGE_TOKEN_FIELD);

    // new token
    if (searchToken) {
      console.log('found query auth token', searchToken);
      setToken(searchToken)
      await getProfile(searchToken);

      const flash = getFlash();
      if (flash) {
        resetFlash();

        if (flash.pathname) {
          navigate(flash.pathname, {
            replace: true,
            state:   flash.state || {},
          });
          setLoading(false);
          return;
        }
      }

      // remove the auth_token part in the search
      navigate('/', { replace: true });

    } else if (token) { // existing token
      await getProfile(token);
    }

    setLoading(false);
  };

  useEffect(() => {
    // console.log('running auth init');
    init();
    return () => null;
  }, [])

  function hasPermissions (required = []) {
    return user.type === 'admin' || required.includes(user.type);
  }

  function logout (reason) {

    let message = undefined;
    // eslint-disable-next-line default-case
    switch (reason) {
      case 'TokenExpiredError':
        message = 'Authentication Expired: Please re-login.';
        break;
      case 'JsonWebTokenError':
        message = 'Invalid Authentication Token';
        break;
      case 'NotBeforeError':
        message = 'Authentication Token not yet valid.';
        break;
    }

    navigate(location.pathname, {
      replace: true,
      state:   {
        authErrorMessage: message || reason,
        prevPath:         location.pathname
      }
    });
    console.log('ping');
    setFlash(location);
    setUser({ ucinetid: null, roles: [] });
    resetToken();

    if (typeof onLogout === 'function') {
      onLogout();
    }
  }

  const authErrorHandler = event => {
    console.log('Event Logged out user', event);
    logout(event.detail && event.detail.message);
  }


  useEffect(() => {
    window.addEventListener('AuthError', authErrorHandler, { once: true });

    return () => {
      window.removeEventListener('AuthError', authErrorHandler);
    }
  }, [ logout, token ]);


  return (
    <AuthContext.Provider value={{ ...state, logout, hasPermissions }}>{children}</AuthContext.Provider>
  );
}
