import CheckIcon                     from '@mui/icons-material/Check';
import ClearIcon                     from '@mui/icons-material/Clear';
import MuiDatePicker                 from '@mui/lab/DatePicker';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
}                                               from '@mui/material';
import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box                                      from '@mui/material/Box';
import Button                                   from '@mui/material/Button';
import IconButton                               from '@mui/material/IconButton';
import List                                     from '@mui/material/List';
import MenuItem                                 from '@mui/material/MenuItem';
import MuiSelect                                from '@mui/material/Select';
import MuiSwitch                                from '@mui/material/Switch';
import MuiTextField                  from '@mui/material/TextField';
import { useCallback, useMemo }      from 'react';
import { toKebabCase, ucFirstWords } from '../../utils/string';
import { InputListContainer }                   from './elements';

export function TextField (
  {
    name,
    required = false,
    component,
    ...muiProps
  }
) {
  // const [errors, setErrors] = useState([]);
  const label = ucFirstWords(name);
  // const ref = useRef();

  const Component = component || MuiTextField;

  // useEffect(() => {
  //   return () => ref.blur && ref.blur();
  // }, []);

  return <Component
    variant="outlined"
    name={name}
    label={label}
    required={required}
    {...muiProps}
    // ref={ref}
  />;
}

export function Switch (
  {
    name,
    value,
    // required,

    // eslint-disable-next-line no-unused-vars
    error,
    // eslint-disable-next-line no-unused-vars
    helperText,

    onChange,
    ...muiProps
  }
) {

  return <MuiSwitch name={name} checked={!!value} onClick={e => onChange(e)} {...muiProps} />;
}

export function TextListField ({
                                 name,
                                 label,
                                 required = false,
                                 helperText,
                                 error,
                                 value: values,
                                 onChange,
                                 ...props
                               }) {
  console.log(values, props)

  const handleChange = (i) => {
    return (e) => {
      const value = e.target.value;
      onChange({ target: { value: [ ...values.slice(0, i), value, ...values.slice(i + 1) ] } });
    }
  }
  const addValue     = () => {
    onChange({ target: { value: [ ...values, '' ] } })
  }
  const removeValue  = (i) => {
    return () => {
      onChange({ target: { value: [ ...values.slice(0, i), ...values.slice(i + 1) ] } })
    }
  }

  return (
    <InputListContainer sx={{ padding: 2, paddingLeft: 3.5 }}>
      <InputLabel shrink={true}>{label}{required ? '*' : ''}</InputLabel>

      {values.map((v, i) => (
        <FormControl sx={{ display: 'block', marginY: 1 }}>
          <Input
            key={i}
            variant="standard"
            fullWidth
            name={name + '01'}
            value={v}
            onChange={handleChange(i)}
            endAdornment={
              <InputAdornment position="end">
                <Tooltip title="Remove Project" placement="left">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={removeValue(i)}
                    onMouseDown={e => e.preventDefault()}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>
      ))}
      <Box display="flex" marginTop={2}>
        <Box flexGrow={1}>
          <FormHelperText error={error}>
            {helperText}
          </FormHelperText>
        </Box>
        <Button onClick={addValue} variant="outlined" color={'success'} size={'small'}>Add Project</Button>
      </Box>
    </InputListContainer>
  );
}

export function DatePicker (
  {
    name,
    required = false,
    onChange,
    onBlur,
    ...muiProps
  }
) {
  const label = ucFirstWords(name);

  const wrapHandler = (handler) => (val) => handler({ target: { value: val } });

  return (
    <MuiDatePicker
      name={name}
      label={label}
      required={required}

      {...muiProps}
      onChange={wrapHandler(onChange)}
      onBlur={wrapHandler(onBlur)}

      renderInput={(params) => <MuiTextField {...params} />}
    />
  )
}

export function SelectList (
  {
    // exclusive = false, // can only one box be checked
    options = [],
    onChange,
    label,
    icon,
    nested = false,
    parentListProps = {},
  }
) {

  const labelId = toKebabCase(label);

  const headEl    = (
    <ListItem key={labelId + '-head'} sx={{ pb: 0 }}>
      {icon && (
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={label} />
    </ListItem>
  );
  const optionsEl = (
    <List key={labelId + '-options'} dense sx={{ pt: 0 }}>
      {options.map((o, i) => {
        const id = `${labelId}-${i}`;
        return (
          <ListItemButton key={i} role={undefined} selected={o.selected} onClick={() => onChange(o)}>
            <ListItemText id={id} primary={o.label} inset />
            {o.selected && <CheckIcon />}
          </ListItemButton>
        )
      })}
    </List>
  );

  const elements = [ headEl, optionsEl ];

  if (nested) {
    return elements;
  }

  return (
    <List {...parentListProps}>
      {elements}
    </List>
  );
}

export function Select (
  {
    name,
    required = false,
    options = [],
    sx = null, selectSx = null,
    error = false, helperText,
    fullWidth = false,
    ...muiProps
  }
) {
  const label = ucFirstWords(name);

  return (
    <FormControl error={error} sx={sx} fullWidth={fullWidth}>
      {/*<TextField label="requestSource />*/}
      <InputLabel id={`${name}-select-label`}>{label}{required && ' *'}</InputLabel>
      <MuiSelect
        labelId={`${name}-select-label`}
        sx={selectSx}
        {...muiProps}
      >
        {options.map((opt, i) => <MenuItem key={i} value={opt.value}>{opt.label}</MenuItem>)}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

// export function _Autocomplete (
//   {
//     options = [],
//
//     // From useForm
//     name,
//     value,
//     label,
//     required = false,
//     error = false,
//     helperText,
//     onChange: _onChange,
//
//     // Custom
//     onAddNew,
//     changeHandlerMap,
//     findOption: _findOption,
//     sx = null,
//     selectSx = null,
//     fullWidth = false,
//     renderOption: _renderOption = 'label',
//     filterText:   _filterText,
//
//     isOptionEqualToValue,
//
//     ...muiProps
//   }
// ) {
//
//   label = label || ucFirstWords(name);
//   if (required) {
//     label = `${label} *`
//   }
//
//   // const renderOption = useMemo(() => {
//   //   if (typeof _renderOption === 'string') {
//   //     return (props, option) => <li {...props}>{option[_renderOption]}</li>
//   //   } else if (typeof _renderOption === 'function') {
//   //     return (props, option) => <li {...props}>{_renderOption(option)}</li>
//   //   }
//   // }, [ _renderOption ])
//
//   const filterText = useMemo(() => {
//     const config = {}
//
//     if (typeof _filterText === 'function') {
//       config.stringify = _filterText;
//     }
//
//     return createFilterOptions(config)
//   }, [ _filterText ])
//
//   const onChange = (e, newVal) => {
//     console.log(typeof newVal, newVal)
//
//     let saveValue = newVal;
//     if (!newVal) {
//       // do nothing
//     } else if (!changeHandlerMap) {
//       // do nothing
//     } else if (typeof changeHandlerMap === 'string') {
//       saveValue = newVal[changeHandlerMap];
//     } else if (typeof changeHandlerMap === 'function') {
//       saveValue = changeHandlerMap(saveValue);
//     }
//
//     _onChange({ target: { value: saveValue } })
//   }
//   const filter   = (options, params) => {
//     // console.log(options, params)
//     const filtered = filterText(options, params);
//
//     // if (params.inputValue !== '') {
//     //   filtered.push({
//     //     inputValue: params.inputValue,
//     //     label:      `Add "${params.inputValue}"`,
//     //   });
//     // }
//
//     // console.log(filtered)
//     return filtered;
//   }
//
//
//   return (
//     <FormControl error={error} sx={sx} fullWidth={fullWidth}>
//       <MuiAutocomplete
//         // disablePortal
//         selectOnFocus
//         clearOnBlur
//         handleHomeEndKeys
//         // freeSolo
//         fullWidth={fullWidth}
//         required={required}
//         value={options.find(o => o.id === value)}
//
//         options={options}
//         onChange={onChange}
//         filterOptions={filter}
//         // renderOption={renderOption}
//         renderInput={(params) => <MuiTextField {...params} label={label} />}
//         isOptionEqualToValue={isOptionEqualToValue}
//
//         sx={{ ...selectSx }}
//
//         {...muiProps}
//       />
//       {helperText && <FormHelperText>{helperText}</FormHelperText>}
//     </FormControl>
//
//   );
// }


export function Autocomplete (
  {
    options = [],
    defaultValue,

    value,
    onChange: _onChange,
    onUpdate,

    name = '',
    label,

    required = false,
    error = false,
    helperText,

    sx = null,
    selectSx = null,
    fullWidth = false,

    filterText: _filterText,

    allowCreate = false,
    onCreate,

    onBlur: _,

    ...muiProps

  }
) {
  label = label || ucFirstWords(name);
  if (required) {
    label = `${label} *`
  }

  if (value === '') {
    console.warn(`Autocomplete for "${name}" has a value of "" (empty string). Consider changing starting value to null.`)
  }

  const onChange = (e, value) => {
    console.log('onChange1', {value})
    if (value.create === true) {
      if (!allowCreate) {
        throw new Error(`Creation has not been allowed for field ${name}`);
      }
      if (typeof onCreate !== 'function') {
        throw new Error(`No onCreate function in ${name}`);
      }
      onCreate(value);
    } else {
      _onChange({ target: { value } }, value);
      if (onUpdate) {
        onUpdate(value);
      }
    }
  }

  const filterText = useMemo(() => {
    const config = {}

    if (typeof _filterText === 'function') {
      config.stringify = _filterText;
    }

    return createFilterOptions(config)
  }, [ _filterText ])

  const filterOptions = useCallback((options, params) => {
    const filtered = filterText(options, params);

    if (allowCreate && params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
        create: true
      });
    }

    return filtered;
  }, [ filterText, allowCreate ])


  return (
    <FormControl error={error} sx={sx} fullWidth={fullWidth}>
      <MuiAutocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys

        // freeSolo

        value={value}
        onChange={onChange}

        required={required}
        fullWidth={fullWidth}

        options={options}
        sx={selectSx}
        renderInput={(params) => <MuiTextField {...params} label={label} />}

        filterOptions={filterOptions}


        {...muiProps}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}
