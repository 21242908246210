import { gql }                      from '@apollo/client';
// import {graphql} from '../client';

export const GQL_MUTATION_CREATE_FACULTY = gql`
  mutation createFaculty ($input: CreateFacultyInput!) {
    createFaculty(input: $input) {
      id

      lastName
      firstName
      ucinetid
      
      createdAt
      updatedAt
    }
  }
`;

export const GQL_MUTATION_UPDATE_FACULTY = gql`
  mutation updateFaculty ($input: UpdateFacultyInput!) {
    updateFaculty(input: $input) {
      id

      lastName
      firstName
      ucinetid
      
      projects

      createdAt
      updatedAt
    }
  }
`;
