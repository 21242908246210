import { SnackbarProvider }        from 'notistack';
import { CookiesProvider }         from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import Apollo                      from './Apollo';

// import { CookiesProvider }         from 'react-cookie';
import { AuthProvider } from './auth';
import DateFns          from './date-fns';
import ThemeProvider    from './theme';


function AppContexts ({ children }) {

  return (
    <ThemeProvider>
      <DateFns>
        <CookiesProvider>
          <Router>
            <SnackbarProvider autoHideDuration={7.5 * 1000}>

              <AuthProvider>
                <Apollo>

                  {children}

                </Apollo>
              </AuthProvider>

            </SnackbarProvider>
          </Router>
        </CookiesProvider>
      </DateFns>
    </ThemeProvider>
  );
}

export default AppContexts;
