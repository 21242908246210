import styled       from '@emotion/styled';
import { useTheme } from '@mui/material';
import Button       from '@mui/material/Button';
import Grid         from '@mui/material/Grid';
import Paper        from '@mui/material/Paper';
import Typography   from '@mui/material/Typography';
import { useState, cloneElement, Fragment } from 'react';
import { Ghost }    from 'react-kawaii';

const Root = styled(Grid)(({ theme }) => ({
  height:          '100vh',
  width:           '100%',
  backgroundColor: theme.palette.grey[50],
}));


export default function Error ({ err }) {

  return (
    <Root container justifyContent="center" alignItems="center">
      <Grid item>

        <Paper elevation={3} sx={{ padding: 2 }}>

          <ErrorBlurb err={err} />

        </Paper>

      </Grid>
    </Root>
  );
}

export function ErrorBlurb ({ err: { stack, message }, align = 'center', hideImage = false, paper = false }) {
  const [ collapsed, setCollapsed ] = useState(true);
  const theme                       = useTheme();

  const Wrapper = paper ? Paper : Fragment;

  return (
    <Wrapper sx={{ m:2, p:2 }}>
      <Grid container spacing={4} justifyContent={align}>
        <Grid item>
          <Ghost size={200} mood="ko" color={theme.palette.error.light} />
        </Grid>

        <Grid item sx={{ maxWidth: 440 }}>
          <Typography variant="h3" color="error">
            An Error Occurred
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {message}
          </Typography>
          <Typography variant="body1" paragraph>
            Unfortunately, There was an error loading the page or your request. Please try again or contact support.
          </Typography>
          <Button variant="text" onClick={() => setCollapsed(c => !c)}>
            Show Stack
          </Button>
        </Grid>

        {collapsed ? null : (
          <Grid item xs={12}>
          <pre>
          {stack}
          </pre>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
}
