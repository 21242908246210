import { gql }                      from '@apollo/client';
// import {graphql} from '../client';

export const GQL_MUTATION_CREATE_SUPPORT = gql`
  mutation createSupport ($input: CreateSupportInput!) {
    createSupport(input: $input) {
      id
      
      student {
        id
        firstName
        lastName
        ucinetid
      }
      faculty {
        id
        firstName
        lastName
        ucinetid
      }

      notes
      quarter
      fundingSource
      
      createdAt
      updatedAt
    }
  }
`;

export const GQL_MUTATION_UPDATE_SUPPORT = gql`
  mutation updateSupport ($input: UpdateSupportInput!) {
    updateSupport(input: $input) {
      id

      student {
        id
        firstName
        lastName
        ucinetid
      }
      faculty {
        id
        firstName
        lastName
        ucinetid
      }

      notes
      quarter
      fundingSource
      
      createdAt
      updatedAt
    }
  }
`;

export const GQL_MUTATION_DELETE_SUPPORT = gql`
  mutation deleteSupport ($id: ID!) {
    deleteSupport(id: $id) {
      id

      student {
        id
        firstName
        lastName
        ucinetid
      }
      faculty {
        id
        firstName
        lastName
        ucinetid
      }

      notes
      quarter
      fundingSource
      
      createdAt
      updatedAt
    }
  }
`;
