import { gql }                      from '@apollo/client';
// import {graphql} from '../client';

export const GQL_MUTATION_CREATE_STUDENT = gql`
  mutation createStudent ($input: CreateStudentInput!) {
    createStudent(input: $input) {
      id

      lastName
      firstName
      ucinetid
      
      createdAt
      updatedAt
    }
  }
`;

export const GQL_MUTATION_UPDATE_STUDENT = gql`
  mutation updateStudent ($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      id

      lastName
      firstName
      ucinetid

      createdAt
      updatedAt
    }
  }
`;
