// import { useQuery }                             from '@apollo/client';
import { createContext, useContext, useEffect } from 'react';
// import { GQL_QUERY_NAVIGATION_TALLY }           from '../data/timeslot';
// import useTabVisibility                         from '../lib/useTabVisiblity';

const defaultData = {
  openPurchases:       0,
  orderedItems:        0,
  inventoriableAssets: 0,
};

export const LayoutContext = createContext(defaultData);

export function useLayout () {
  return useContext(LayoutContext);
}

export function LayoutContextProvider ({ children }) {
  // const { data, refetch: update } = useQuery(GQL_QUERY_NAVIGATION_TALLY);
  // const isTabVisible              = useTabVisibility()

  // const state = {
  //   openPurchases:       data ? data.openPurchases.count : defaultData.openPurchases,
  //   orderedItems:        data ? data.orderedItems.count : defaultData.orderedItems,
  //   inventoriableAssets: data ? data.inventoriableAssets.count : defaultData.inventoriableAssets,
  // };

  // useEffect(() => {
    // if (isTabVisible) {
    //   update();
    //
    //   const interval = setInterval(() => {
    //     update();
    //   }, 60 * 1000);
    //
    //   return () => clearInterval(interval);
    // }
  // }, [ isTabVisible, update ]);

  return <LayoutContext.Provider value={{  }}>{children}</LayoutContext.Provider>;
}
