import { gql }          from '@apollo/client';
// import {graphql} from '../client';

export const GQL_QUERY_GET_SUPPORT = gql`
  query getSupport ($id: ID!) {
    getSupport(id: $id) {
      id
      
      student {
        id
        firstName
        lastName
        ucinetid
      }
      faculty {
        id
        firstName
        lastName
        ucinetid
      }
      
      notes
      quarter
      fundingSource
      
      createdAt
      updatedAt
    }
  }
`;


export const GQL_QUERY_LIST_SUPPORT = gql`
  query listSupport ($filter: SupportFilterInput, $sort: SortInput, $page: PaginationInput) {
    listSupport(filter: $filter, sort: $sort, page: $page) {
      items {
        id
        
        student {
          id
          firstName
          lastName
          ucinetid
        }
        faculty {
          id
          firstName
          lastName
          ucinetid
        }

        notes
        quarter
        fundingSource
        createdAt
        updatedAt
      }
      meta {
        count
        pages
      }
    }
  }
`;
