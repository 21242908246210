import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import Box                                                                   from '@mui/material/Box';
import useMediaQuery                                                         from '@mui/material/useMediaQuery';
import PropTypes                                                             from 'prop-types';
import { useState }                                                          from 'react';

export function FormDialog (
  {
    open,
    onClose:  _onClose,
    onReturn: _onReturn,
    title,
    titleAction = null,
    children,
    actions: _actions,
    contentSx = {},
    dialogSx = {},
    loading = false,
    loadingProps = {}
  }
) {
  const isXS = useMediaQuery(theme => theme.breakpoints.only('xs'));

  const checkKey = ({ key }) => {
    if (key === 'Enter' && _onReturn) {
      _onReturn();
    }
  }
  const onClose  = (func) => {
    if (typeof func === 'function') {
      func();
    }
    _onClose();
  }

  let actions = _actions;
  if (typeof _actions === 'function') {
    actions = _actions(onClose);
  }

  return (
    <Dialog
      fullScreen={isXS}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      sx={dialogSx}
    >
      {title && (
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 1.5, display: 'flex' }}>
          <span style={{ flexGrow: 1 }}>{title}</span>
          {titleAction}
        </DialogTitle>
      )}


      <DialogContent onKeyPress={checkKey} sx={{
        width:    { xs: '100%', sm: '500px' },
        maxWidth: '100%',
        ...contentSx
      }}>
        {loading ? <LinearProgress {...loadingProps} height={'4px'} /> : <Box height={'4px'} width={'100%'} />}

        {children}

      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  )

}

FormDialog.propTypes = {
  open:     PropTypes.bool,
  onClose:  PropTypes.func,
  children: PropTypes.node.isRequired,
  title:    PropTypes.string,
  actions:  PropTypes.oneOfType([ PropTypes.func, PropTypes.node ])
}


export function useDialogCtrl (defState = {}) {
  const [ state, setState ] = useState(defState);

  const openDialog  = ({ title, actions = [], onClose, children, ...other } = {}) => {
    setState({ open: true, title, actions, onClose: onClose || closeDialog, children, ...other });
  };
  const closeDialog = (...args) => {
    setState({ open: false });
    if (typeof state.onClose === 'function') {
      state.onClose(...args);
    }
  }

  const { title, open, actions, children, onClose, ...other } = state;

  return {
    title,
    open,
    actions,
    children,
    onClose: closeDialog,

    openDialog,
    closeDialog,

    getDialog: () => (
      <FormDialog
        title={title}
        open={open}
        actions={actions}
        onClose={onClose}
      >
        {state.children}
      </FormDialog>
    ),

    // for communicating random data to the form
    ...other
  }
}

