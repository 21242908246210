import { cloneElement } from 'react';


// eslint-disable-next-line no-extend-native
Array.prototype.joinElements = function joinElements (glue) {
  if (this.length <= 1) {
    // this this instead of direct return to propagate key property consistently
    return this.map((item, i) => cloneElement(item, {key: i}));
  }
  let out = [];
  this.forEach((item, i) => {
    if (i > 0) {
      out.push(cloneElement(glue, {key: `${i}-divider`}));
    }
    out.push(cloneElement(item));
  })
  return out;
}

// eslint-disable-next-line no-extend-native
Array.prototype.keyedIndexOf = function keyedIndexOf (key, needle) {
  let outIndex = -1;
  for (let i = 0; i < this.length; i++) {
    if (this[i][key] === needle) {
      outIndex = i;
      break;
    }
  }
  return outIndex;
}
