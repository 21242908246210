import styled     from '@emotion/styled';
import MenuIcon   from '@mui/icons-material/Menu';
import { Tab }    from '@mui/material';
import Box        from '@mui/material/Box';
import Button     from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link       from '@mui/material/Link';
import List       from '@mui/material/List';
import Menu       from '@mui/material/Menu';
import MenuItem   from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddIcon from '@mui/icons-material/AddCircle';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


import { useState }    from 'react';
import { environment } from '../config';
import { useAuth }     from '../Context/auth';
import UserIcon        from './UserIcon';

const Root    = styled('nav')(({ theme }) => ({
  display: 'flex',
}));
const NavList = styled(List)(({ theme }) => ({}));


function LinkTab (props) {
  return (
    <Tab
      component={Link}
      {...props}
    />
  );
}

// export function Nav () {
//   const { isLoggedIn, logout } = useAuth();
//   // const { data: tally } = useLayout();
//   //
//   // const {
//   //         openPurchases,
//   //         orderedItems,
//   //         inventoriableAssets
//   //       } = tally;
//
//   return (
//     <Root>
//       <Box sx={{ flexGrow: 1 }} />
//
//       {isLoggedIn ? (
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="icon position tabs example"
//         >
//           <LinkTab icon={<ShoppingCartIcon />} href={'/'} iconPosition='start' label="Register" />
//           <LinkTab icon={<ShoppingCartIcon />} href={'/login'} iconPosition="start" label="Login" />
//         </Tabs>
//       ) : (
//         <>
//           <NavButton component={Link} href="/purchase" startIcon={<ShoppingCartIcon />}>
//             Purchases
//           </NavButton>
//           <NavButton component={Link} href="/receiving" startIcon={<LocalShippingIcon />}>
//             Receiving
//           </NavButton>
//         </>
//       )}
//
//     </Root>
//   );
// }





const pages    = [
  // { text: 'Login', Icon: LoginIcon, href: '/', onClick: () => window.location = loginUrl(), adminOnly: false, loggedIn: false },
  { text: 'Add Support', Icon: AddIcon, href: '/', adminOnly: false, loggedIn: true },
  { text: 'Faculty', Icon: SchoolIcon, href: '/faculty', adminOnly: false, loggedIn: true },
  { text: 'Students', Icon: PeopleIcon, href: '/student', adminOnly: false, loggedIn: true },
  ...(environment === 'dev' ? [{ text: 'Dev', Icon: AdminPanelSettingsIcon, href: '/dev', adminOnly: false, loggedIn: true }] : []),
];
const filterPages = (pages, isLoggedIn = false, isAdmin = false) => {
  return pages.filter(p =>
    (p.loggedIn === null || p.loggedIn === isLoggedIn)
    && (!p.adminOnly || isAdmin)
  );
}

export function Nav () {
  const { isLoggedIn, isAdmin, loginUrl, signOut, user }            = useAuth();
  const [ anchorElNav, setAnchorElNav ]   = useState(null);

  // const isLoggedIn = true;

  const handleOpenNavMenu  = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical:   'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {filterPages(pages, isLoggedIn, isAdmin).map((page) => (
            <MenuItem component={Link} key={page.text} href={page.href} onClick={e => { if (page.onClick) page.onClick(e); handleCloseNavMenu(e) }}>
              <page.Icon />
              <Typography component='span' sx={{pl: 1}}>{page.text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}
      >
        Cyber-Security Reminders
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
        {filterPages(pages, isLoggedIn, isAdmin).map((page) => (
          <Button
            component={Link}
            key={page.text}
            href={page.href}
            onClick={e => { if (page.onClick) page.onClick(e); handleCloseNavMenu(e) }}
            sx={{ mt: 2, mb: 1, color: 'white', display: 'block' }}
          >
            {page.text}
          </Button>
        ))}
      </Box>

      <Box sx={{ flexGrow: 0, pr: 1 }}>
        {isLoggedIn
         ? <UserIcon />
         : <Box width='44px' />
        }
      </Box>
    </>
  );
}
