import { gql } from '@apollo/client';

export const GQL_QUERY_LIST_FACULTY_W_CREDIT = gql`
  query listFacultyWithCredit ($filter: FacultyFilterInput, $sort: SortInput, $page: PaginationInput) {
    listFaculty(filter: $filter, sort: $sort, page: $page) {
      items {
        id

        lastName
        firstName
        ucinetid
        availableCredit

        createdAt
        updatedAt
      }
      meta {
        count
        pages
      }
    }
  }
`;



export const GQL_QUERY_GET_FACULTY_ALL = gql`
  query getFaculty ($id: ID!) {
    getFaculty(id: $id) {
      id
      
      ucinetid
      lastName
      firstName
      initialCredit
      availableCredit

      projects

      updatedAt
      createdAt
    }
  }
`;

export const GQL_QUERY_LIST_FACULTY_SUPPORT = gql`
  query listFacultySupport ($id: ID!, $faculty: String, $student: String, $quarter: String, $page: PaginationInput, $sort: SortInput) {
    listSupport(
      filter: {
        faculty: $id,
        faculty_text: $faculty,
        student_text: $student,
        quarter: $quarter
      }
      page: $page
      sort: $sort
    ) {
      items {
        id

        student {
          id
          ucinetid
          lastName
          firstName
        }
        quarter
        fundingSource
        notes
        
        updatedAt
        createdAt
      }
    }
  }
`;
