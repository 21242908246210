import { gql } from '@apollo/client';

export const GQL_QUERY_LIST_STUDENTS = gql`
  query listStudents ($filter: StudentFilterInput, $sort: SortInput, $page: PaginationInput) {
    listStudents(filter: $filter, sort: $sort, page: $page) {
      items {
        id

        lastName
        firstName
        ucinetid

        createdAt
        updatedAt
      }
      meta {
        count
        pages
      }
    }
  }
`;



export const GQL_QUERY_GET_STUDENT_ALL = gql`
  query getStudent ($id: ID!) {
    getStudent(id: $id) {
      id
      
      ucinetid
      lastName
      firstName

      updatedAt
      createdAt
    }
  }
`;

export const GQL_QUERY_LIST_STUDENTS_SUPPORT = gql`
  query listStudentSupport ($id: ID!, $faculty: String, $student: String, $quarter: String, $page: PaginationInput, $sort: SortInput) {
    listSupport(
      filter: {
        student: $id,
        faculty_text: $faculty,
        student_text: $student,
        quarter: $quarter
      }
      page: $page
      sort: $sort
    ) {
      items {
        id

        faculty {
          id
          ucinetid
          lastName
          firstName
        }
        quarter
        fundingSource
        notes
        
        updatedAt
        createdAt
      }
    }
  }
`;
