import { useQuery }                                                               from '@apollo/client';
import CommentIcon                                                                from '@mui/icons-material/Comment';
import EditIcon                                                                   from '@mui/icons-material/Edit';
import LinkIcon                                                                   from '@mui/icons-material/Link';
import MoreVertIcon                                                               from '@mui/icons-material/MoreVert';
import {
  Hidden,
  ListItem,
  ListItemText,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
}                                                                                 from '@mui/material';
import Avatar                                                                     from '@mui/material/Avatar';
import Box                                                                        from '@mui/material/Box';
import Button                                                                     from '@mui/material/Button';
import Divider                                                                    from '@mui/material/Divider';
import Grid                                                                       from '@mui/material/Grid';
import IconButton                                                                 from '@mui/material/IconButton';
import Link                                                                       from '@mui/material/Link';
import List                                                                       from '@mui/material/List';
import Paper                                                                      from '@mui/material/Paper';
import Typography                                                                 from '@mui/material/Typography';
import formatDate                                                                 from 'date-fns/format';
import { useEffect }                                                              from 'react';
import { useParams }                                                              from 'react-router-dom';
import { useAuth }                                                                from '../Context/auth';
import { graphql }                                                                from '../data/client';
import { FormDialog, FormRow, TextField, TextListField, useDialogCtrl, useForm, } from '../lib/Form';
import { MoreActionMenu }                                                         from '../lib/menuElements';
import useSubmitSnack                                                             from '../lib/snacks';
import usePagination                                                              from '../lib/usePagination';
import { ErrorBlurb }                                                             from '../other-pages/Error';
import quarterSort                                                                from '../utils/quarterSort';
import { GQL_MUTATION_UPDATE_FACULTY }                                            from './gql/mutations';
import { GQL_QUERY_GET_FACULTY_ALL, GQL_QUERY_LIST_FACULTY_SUPPORT }              from './gql/queries';


export default function GetFaculty () {
  const { id }                      = useParams();
  const { sort, page, filter, set } = usePagination({
    filter: { faculty: id },
    sort: { field: 'createdAt', direction: -1 }
  });
  const {
          data,
          loading,
          error,
          refetch: refetchMain
        }                           = useQuery(GQL_QUERY_GET_FACULTY_ALL, { variables: { id } });
  const {
          data:    supportData,
          loading: supportLoading,
          error:   supportError,
          refetch: refetchSupport
        }                           = useQuery(GQL_QUERY_LIST_FACULTY_SUPPORT, {
    variables: {
      id, sort, page, student: filter.student_text
    }
  });
  const snack                       = useSubmitSnack();
  const editDialogCtrl              = useDialogCtrl();
  const { hasPermissions } = useAuth();
  const hasPerms           = hasPermissions([ 'studentAffairs' ])




  const updateFaculty = (input) => {
    snack.start('Saving Faculty Member changes');
    graphql({
      mutation:  GQL_MUTATION_UPDATE_FACULTY,
      variables: { input }
    })
      .then(() => {
        snack.success('Saved Faculty Member changes')
        refetchMain();
      })
      .catch(snack.catchError)
    ;
  }

  useEffect(() => {
    refetchSupport({ page, sort, id, student: filter.student_text })
  }, [ page, sort, filter, id ])


  let bodyInfo;
  if (error) {
    bodyInfo = <ErrorBlurb err={error} />;
  } else if (loading) {
    bodyInfo = <GetFacultySkeleton />;
  } else if (data) {
    bodyInfo = <GetFacultyBody faculty={data.getFaculty} onEdit={() => editDialogCtrl.openDialog()} canEdit={hasPerms} />;
  }


  let bodySupport;
  if (supportError) {
    bodySupport = <ErrorBlurb err={supportError} />;
  } else if (supportLoading) {
    bodySupport = <GetFacultySupportSkeleton />;
  } else if (supportData) {
    bodySupport =
      <GetFacultySupportBody support={supportData.listSupport.items} />;
  }

  const pagination = (
    <Box textAlign="center">
      {/*<Pagination*/}
      {/*  count={supportData ? supportData.listSupport.meta.pages : 3}*/}
      {/*  page={page.page}*/}
      {/*  sx={{ display: 'inline-block' }}*/}
      {/*  onChange={(e, newPage) => set('page', { ...page, page: newPage })}*/}
      {/*/>*/}
    </Box>
  );


  return (
    <Paper sx={{ p: 2 }}>

      {bodyInfo}

      <Divider />

      <Box display="flex" alignItems="center">
        <Box width={200}>
          <TextField
            fullWidth size="small"
            variant={'standard'}
            name="filter"
            label="Filter"
            value={filter.text}
            onChange={e => set('filter', { ...filter, student_text: e.target.value })}
          />
        </Box>
        <Box flexGrow={12}>
          {pagination}
        </Box>
        <Box width={200}>
        </Box>
      </Box>

      {bodySupport}

      {data && data.getFaculty && (
        <EditFacultyDialog
          open={editDialogCtrl.open}
          onClose={() => editDialogCtrl.closeDialog()}
          onSubmit={(data) => {
            updateFaculty(data);
            editDialogCtrl.closeDialog()
          }}
          faculty={data.getFaculty}
        />
      )}

    </Paper>
  )

}


function GetFacultyBody ({ faculty: fac, onEdit, canEdit=false }) {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">
            {fac.lastName}, {fac.firstName}
          </Typography>
          <Typography variant="subtitle1">
            {fac.ucinetid}@uci.edu
          </Typography>
        </Box>

        <Tooltip title={`${fac.availableCredit} TIRF Credits available`} placement="left">
          <Avatar sx={{ bgcolor: fac.availableCredit >= 1 ? 'success.main' : 'gray.500' }}>
            {fac.availableCredit}
          </Avatar>
        </Tooltip>
        <Box paddingX={2}>
          <MoreActionMenu actions={[
            {
              icon:    <EditIcon />,
              text:    'Edit Faculty Member',
              onClick: onEdit,
              disabled: !canEdit
            }
          ]}>
            <IconButton edge="end" aria-label="More Actions">
              <MoreVertIcon />
            </IconButton>
          </MoreActionMenu>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Grid container>

        <Grid item xs={12} sm={6}>

          <List>
            <ListItem>
              <ListItemText primary={fac.firstName} secondary="First Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={fac.lastName} secondary="Last Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={fac.ucinetid} secondary="UCINetID" />
            </ListItem>
            {fac.initialCredit ? (
              <ListItem>
                <ListItemText primary={fac.initialCredit} secondary="Initial TIRF" />
              </ListItem>
            ) : null}
            <ListItem>
              <ListItemText primary={`${fac.ucinetid}@uci.edu`} secondary="Email Address" />
            </ListItem>
          </List>

        </Grid>

        <Hidden smUp>
          <Divider />
        </Hidden>

        <Grid item sx={{ p: 2 }} xs={12} sm={6}>

          <Typography variant="subtitle1">
            Projects
          </Typography>
          <List sx={{ width: '300px' }} dense>
            {[ ...fac.projects ].sort().map((p, i) => (
              <ListItem key={i}>
                <ListItemText primary={p} />
              </ListItem>
            ))}
          </List>
        </Grid>

      </Grid>
    </>
  );
}

function GetFacultySupportBody ({ support: _support }) {

  const support = [..._support].sort(quarterSort(-1));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell>Funding Source</TableCell>
            <TableCell>Quarter</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right" width={100}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {support.map(s => (
            <TableRow key={s.id}>
              <TableCell>{s.student.lastName}, {s.student.firstName}</TableCell>
              <TableCell>{s.fundingSource}</TableCell>
              <TableCell>{s.quarter}</TableCell>
              <TableCell align="right">{formatDate(s.createdAt, 'MM/dd/yyyy hh:mm aa')}</TableCell>
              <TableCell align="right" width={100}>
                {s.notes ? (
                  <Tooltip title={s.notes} placement="left">
                    <CommentIcon fontSize="small" />
                  </Tooltip>
                ) : null}
                <Link href={`/support/${s.id}`} sx={{ ml: 1 }}>
                  <LinkIcon />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function GetFacultySkeleton () {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Typography variant="subtitle1">
            <Skeleton width="35%" />
          </Typography>
        </Box>

        <Skeleton variant="circular" height={40} width={40} />
        <Box paddingX={2} color="gray">
          <IconButton edge="end" aria-label="More Actions">
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Grid container>

        <Grid item sm={6}>

          <List sx={{ width: '300px' }}>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="First Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="Last Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="UCINetID Name" />
            </ListItem>
            {/*<ListItem>*/}
            {/*  <ListItemText primary={<Skeleton />} secondary="Initial TIRF" />*/}
            {/*</ListItem>*/}
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="Email Address" />
            </ListItem>
          </List>

        </Grid>

        <Hidden smUp>
          <Divider />
        </Hidden>

        <Grid item sx={{ p: 2 }} sm={6}>

          <Typography variant="subtitle1">
            Projects
          </Typography>
          <List sx={{ width: '300px' }} dense>
            <ListItem>
              <ListItemText primary={<Skeleton />} />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} />
            </ListItem>
          </List>
        </Grid>

      </Grid>
    </>
  )
}

function GetFacultySupportSkeleton () {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell>Funding Source</TableCell>
            <TableCell>Quarter</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right" width={100}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.from(new Array(4)).map((_, i) => (
            <TableRow key={i}>
              <TableCell><Skeleton width={100} /></TableCell>
              <TableCell><Skeleton width={100} /></TableCell>
              <TableCell><Skeleton width={100} /></TableCell>
              <TableCell align="right"><Skeleton width={100} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function EditFacultyDialog (
  {
    open,
    onClose:  _onClose,
    onSubmit: _onSubmit,
    faculty
  }
) {
  const { getField, getValue, reset, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: {
      id:            faculty.id,
      ucinetid:      faculty.ucinetid,
      lastName:      faculty.lastName,
      firstName:     faculty.firstName,
      initialCredit: faculty.initialCredit,
      projects:      faculty.projects,
    },
    rules:         {
      id:            { required: true },
      ucinetid:      { lowercase: true, required: true },
      lastName:      { trim: false, required: true },
      firstName:     { trim: false, required: true },
      initialCredit: { isFloat: true },
      projects:      { isList: true, listMin: 0, trim: false, listNoDuplicates: true },
    },
  });
  const canSubmit                                                       = isValid && !hasErrors;

  const onClose = () => {
    reset();
    _onClose();
  }

  const onSubmit = handleSubmit(_onSubmit);

  return (
    <FormDialog
      title={`Edit ${faculty.lastName}, ${faculty.firstName}`}
      open={open}
      onClose={onClose}
      actions={[
        <Button key="cancelButton" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submitButton" variant="contained" onClick={onSubmit} disabled={!canSubmit}>
          Save Changes
        </Button>
      ]}
      onReturn={onSubmit}
    >
      <FormRow>
        <TextField fullWidth {...getField('id')} label="ID" disabled />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('ucinetid')} label="UCINetID" disabled />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('firstName')} label="First Name" />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('lastName')} label="Last Name" />
      </FormRow>

      <FormRow>
        <TextField
          fullWidth
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          {...getField('initialCredit')}
          label="Initial Credit"
        />
      </FormRow>

      {/*<FormRow>*/}
      <TextListField {...getField('projects')} />
      {/*</FormRow>*/}

    </FormDialog>
  )

}






