import { gql } from '@apollo/client';

export const GQL_QUERY_GET_CREATE_SUPPORT_DATA = gql`
  query getCreateSupportData {
    students: listStudents (page: {limit: 0}, sort: { field: "lastName", direction: 1, next: { field: "firstName", direction: 1 } }) {
      items {
        id
        ucinetid
        lastName
        firstName
      }
    }
    faculty: listFaculty (page: {limit: 0}, sort: { field: "lastName", direction: 1, next: { field: "firstName", direction: 1 } }) {
      items {
        id
        lastName
        firstName
        ucinetid
        projects
      }
    }
  }
`;

export const GQL_QUERY_GET_CREATE_SUPPORT_FACULTY_CREDITS = gql`
  query getCreateSupportFacultyCredits ($id: ID!) {
    getFaculty(id: $id) {
      availableCredit
    }
  }
`;
