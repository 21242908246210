import LinkIcon                                                     from '@mui/icons-material/Link';
import Button                                                       from '@mui/material/Button';
import IconButton                                                   from '@mui/material/IconButton';
import Link                                                         from '@mui/material/Link';
import { graphql }                                                  from '../../data/client';
import { GQL_MUTATION_CREATE_FACULTY, GQL_MUTATION_UPDATE_FACULTY } from '../../data/faculty';
import { GQL_MUTATION_CREATE_STUDENT }                              from '../../data/student';
import { FormDialog, FormRow, TextField, useDialogCtrl, useForm }   from '../../lib/Form';
import useSubmitSnack                                               from '../../lib/snacks';

export function AddFacultyDialog (
  {
    open,
    onClose:  _onClose,
    onSubmit: _onSubmit,
  }
) {
  const { getField, reset, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: {
      firstName: '',
      lastName:  '',
      ucinetid:  '',
    },
    rules:         {
      firstName: { required: true, trim: false },
      lastName:  { required: true, trim: false },
      ucinetid:  { required: true, lowercase: true },
    }
  });

  const canSubmit = isValid && !hasErrors;

  const onClose = () => {
    reset();
    _onClose();
  }

  const onSubmit = handleSubmit(_onSubmit);


  return (
    <FormDialog
      title="Add Faculty Member"
      open={open}
      onClose={onClose}
      actions={[
        <Button key="cancelButton" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submitButton" variant="contained" onClick={onSubmit} disabled={!canSubmit}>
          Save Changes
        </Button>
      ]}
    >
      <FormRow>
        <TextField {...getField('ucinetid')} label="UCINetID" fullWidth />
      </FormRow>
      <FormRow>
        <TextField {...getField('firstName')} label="First Name" fullWidth />
      </FormRow>
      <FormRow>
        <TextField {...getField('lastName')} label="Last Name" fullWidth />
      </FormRow>
    </FormDialog>
  )
}

export function AddFacultyProjectDialog (
  {
    open,
    onClose:  _onClose,
    onSubmit: _onSubmit,
    initText
  }
) {
  const { getField, reset, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: { project: initText ?? '', },
    rules:         {
      project: { required: true, trim: false },
    }
  });

  const canSubmit = isValid && !hasErrors;

  const onClose = () => {
    reset();
    _onClose();
  }

  const onSubmit = handleSubmit(_onSubmit);

  return (
    <FormDialog
      title="Add Project/Funding Source"
      open={open}
      onClose={onClose}
      actions={[
        <Button key="cancelButton" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submitButton" variant="contained" onClick={onSubmit} disabled={!canSubmit}>
          Add Project
        </Button>
      ]}
    >
      <FormRow>
        <TextField {...getField('project')} autoFocus label="Project Name" fullWidth />
      </FormRow>
    </FormDialog>
  )
}


export function AddStudentDialog (
  {
    open,
    onClose:  _onClose,
    onSubmit: _onSubmit,
  }
) {
  const { getField, reset, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: {
      firstName: '',
      lastName:  '',
      ucinetid:  '',
    },
    rules:         {
      firstName: { required: true, trim: false },
      lastName:  { required: true, trim: false },
      ucinetid:  { required: true, lowercase: true },
    }
  });

  const canSubmit = isValid && !hasErrors;

  const onClose = () => {
    reset();
    _onClose();
  }

  const onSubmit = handleSubmit(_onSubmit);


  return (
    <FormDialog
      title="Add Student"
      open={open}
      onClose={onClose}
      actions={[
        <Button key="cancelButton" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submitButton" variant="contained" onClick={onSubmit} disabled={!canSubmit}>
          Save Changes
        </Button>
      ]}
    >
      <FormRow>
        <TextField {...getField('ucinetid')} label="UCINetID" fullWidth />
      </FormRow>
      <FormRow>
        <TextField {...getField('firstName')} label="First Name" fullWidth />
      </FormRow>
      <FormRow>
        <TextField {...getField('lastName')} label="Last Name" fullWidth />
      </FormRow>
    </FormDialog>
  )
}



export function useSupportDialogs (
  {
    refetch,
    getFaculty,
    setValue
  }
) {
  const snack                 = useSubmitSnack();
  const addFacultyProjectCtrl = useDialogCtrl();
  const addFacultyProject     = (data) => {
    snack.start(`Adding Project to ${data.firstName} ${data.lastName}`)
    const fac = getFaculty();
    graphql({
      mutation:  GQL_MUTATION_UPDATE_FACULTY,
      variables: {
        input: { id: fac.id, projects: [ ...fac.projects, data.project ].sort() }
      }
    })
      .then(({ data: d }) => {
        console.log(d)
        const fac = d.updateFaculty;
        snack.success(`Updated ${fac.firstName} ${fac.lastName}`, {
          action: (
                    <IconButton component={Link} href={`/faculty/${fac.id}`}>
                      <LinkIcon />
                    </IconButton>
                  )
        })

        return refetch()
          .then(() => setValue('fundingSource', data.project))
      })
      .catch(snack.catchError())
    ;
  }

  const addFacultyCtrl = useDialogCtrl();
  const addFaculty     = (data) => {
    snack.start(`Creating Faculty Member: ${data.firstName} ${data.lastName}`)
    graphql({
      mutation:  GQL_MUTATION_CREATE_FACULTY,
      variables: {
        input: { ...data, initialCredit: 0 }
      }
    })
      .then(({ data: d }) => {
        const fac = d.createFaculty;
        snack.success(`Created ${fac.firstName} ${fac.lastName}`, {
          action: (
                    <IconButton component={Link} href={`/faculty/${fac.id}`}>
                      <LinkIcon />
                    </IconButton>
                  )
        })

        return refetch()
          .then(() => setValue('faculty', fac.id))
      })
      .catch(snack.catchError())
    ;
  }

  const addStudentCtrl = useDialogCtrl();
  const addStudent     = (data) => {
    snack.start(`Creating Student: ${data.firstName} ${data.lastName}`)
    graphql({
      mutation:  GQL_MUTATION_CREATE_STUDENT,
      variables: {
        input: data
      }
    })
      .then(({ data: d }) => {
        const stu = d.createStudent;
        snack.success(`Created ${d.firstName} ${d.lastName}`, {
          action: (
                    <IconButton component={Link} href={`/student/${stu.id}`}>
                      <LinkIcon />
                    </IconButton>
                  )
        })

        return refetch()
          .then(() => setValue('student', stu.id))
      })
      .catch(snack.catchError())
    ;
  }


  return {
    addFacultyProjectCtrl,
    addFacultyProject,
    addFacultyCtrl,
    addFaculty,
    addStudentCtrl,
    addStudent,
    snack,
  }
}
