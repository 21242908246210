import { gql }          from '@apollo/client';
// import {graphql} from '../client';

export const GQL_QUERY_GET_USER = gql`
  query getUser ($id: ID!) {
    getUser(id: $id) {
      id

      lastName
      firstName
      ucinetid
      type

      canLogin
      isAdmin

      createdAt
      updatedAt
    }
  }
`;


export const GQL_QUERY_LIST_USERS = gql`
  query listUsers ($filter: UserFilterInput, $sort: SortInput, $page: PaginationInput) {
    listUsers(filter: $filter, sort: $sort, page: $page) {
      items {
        id

        lastName
        firstName
        ucinetid
        type

        canLogin
        isAdmin

        createdAt
        updatedAt
      }
      meta {
        count
        pages
      }
    }
  }
`;
