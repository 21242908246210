export function ucFirst      (str) {
  return str.length === 0
         ? str
         : str.slice(0, 1).toUpperCase() + str.slice(1)
    ;
}
export function ucFirstWords (str) {
  return str
    .split(/[\s_-]+/g)
    .filter(i => !!i)
    .map(ucFirst)
    .join(' ')
    ;
}

export function toKebabCase (str) {
  return str && str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
}
