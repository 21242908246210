const quarterOrder = {
  'Winter': 2,
  'Spring': 3,
  'Summer': 4,
  'Fall': 5,
};
const quarterNumber = (q) => {
  // eslint-disable-next-line no-unused-vars
  const [_, tm, yr] = q.match(/(\w+) (\d+)/);
  return Number(yr + quarterOrder[tm] );
}

export default function getQuarterSort(direction = 1) {
  return function quarterSort ({quarter: _qA, createdAt: cA}, {quarter: _qB, createdAt: cB}) {
    const qA = quarterNumber(_qA);
    const qB = quarterNumber(_qB);
    if (qA > qB) return 1 * direction;
    if (qA < qB) return -1 * direction;
    if (cA > cB) return 1 * direction;
    if (cA < cB) return -1 * direction;
    return 0;
  }
}
