import { gql }                      from '@apollo/client';
import { client as apollo }         from '../../Context/Apollo';

export const GQL_QUERY_REDIRECT_TOKEN = gql`
  query RedirectUrlQuery($url: String!) {
    token: redirectUrl(url: $url)
  }
`;

export const GQL_QUERY_PROFILE = gql`
  query getProfile {
    profile {
      token
      user {
        id
        ucinetid
        firstName
        lastName
        type
        createdAt
        updatedAt
        isAdmin
      }
    }
  }
`;

export function getProfileQuery () {
  return apollo.query({
    query: GQL_QUERY_PROFILE
  })
}
