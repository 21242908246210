import { useReducer }      from 'react';
import { useSearchParams } from 'react-router-dom';
import _noop               from '../utils/noop';


const translateStateToParams = (data) => {
  return Object
    .getOwnPropertyNames(data)
    .reduce((obj, k) => ({ ...obj, ...data[k] }), {})
    ;
}
const translateParamsToState = (data) => {
  const { direction, field, page, limit, ...rest } = data;
  return {
    sort:   { field, direction },
    page:   { page: Number(page), limit: Number(limit) },
    filter: rest
  }
}
const _page = { limit: 25, page: 1 };
const _sort = { field: 'creaedAt', direction: -1 };
const _filter = {};



export default function usePagination (
  {
    filter: defaultFilter = _filter,
    sort:   defaultSort = _sort,
    page:   defaultPage = _page,
    syncSearchParams = false,
    ParseObject:
      {
        flattenObject = false,
        structureObject = false
      } = {},
  }
) {
  const defaultState                         = {
    filter: defaultFilter,
    sort:   defaultSort,
    page:   defaultPage
  };
  const [ _searchParams, setSearchParams ]   = useSearchParams();
  const flatDefault                          = (flattenObject || translateStateToParams)(defaultState);
  const [ state, dispatch ] = useReducer(reducer, defaultState);

  function reducer (state, { type, payload }) {
    let out;
    switch (type) {
      case 'sort':
        out = { ...state, sort: payload };
        break;
      case 'page':
        out = { ...state, page: payload };
        break;
      case 'filter':
        out = { ...state, filter: payload };
        break;
      case 'reset':
        out = { ...state, sort: defaultSort, page: defaultPage, filter: defaultFilter };
        break;
      case 'update':
        return syncSearchParams ? state : readSearch();
      default:
        throw new Error(`Pagination Reducer action not valid, received "${type}"`);
    }

    if (syncSearchParams) {
      updateSearch(out, { replace: true });
    }
    return out;
  }

  function readSearch () {
    const func = structureObject || translateParamsToState;
    const out  = objectEach({ ...flatDefault, ...parseForArrays(_searchParams) }, convert);
    // console.log('ping')
    return func(out);
  }

  function updateSearch (data, { replace = true, state } = {}) {
    const flatData = translateStateToParams(data);

    let out = {};
    Object.getOwnPropertyNames(flatData)
      .forEach(k => {
        const v = flatData[k];

        if (v instanceof Date) {
          out[k] = v.toISOString();
        } else if (Array.isArray(v) && !arrayEquals(flatDefault[k], v)) {
          out[`${k}[]`] = v;
        } else if (flatDefault[k] !== v && v !== '') {
          out[k] = v;
        }

      })

    setSearchParams(out, { replace, state })
  }


  const set   = (field, payload) => {
    dispatch({ type: field, payload });
  }
  const reset = () => {
    dispatch({ type: 'reset' });
  }

  return {
    ...(syncSearchParams
        ? readSearch()
        : state
    ),
    set, reset,
  }
}



const isoReg = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

function convert (data) {
  if (data === 'true') return true;
  if (data === 'false') return false;
  if (isoReg.test(data)) return new Date(data);
  if (!isNaN(Number(data))) return Number(data);

  return data;
}



function parseForArrays (urlSearchParams) {
  // console.log('parseForArray', Array.from(urlSearchParams.entries()))
  let out = {};

  for (let [ k, v ] of urlSearchParams) {
    if (/\[]$/.test(k)) {
      k = k.replace(/\[]$/, '');
      if (!out[k]) {
        out[k] = [];
      }
      out[k].push(v);
    } else {
      out[k] = v;
    }
  }

  return out;
}

// function parseForArraysEntities (urlSearchParams) {
//   const out = parseForArrays(urlSearchParams);
//   return Object.getOwnPropertyNames(out)
//     .map(k => [ k, out[k] ])
//     ;
// }

function arrayEquals (arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (
    arr1.filter(a => !arr2.includes(a)).length
    || arr2.filter(a => !arr1.includes(a)).length
  ) {
    return false;
  }

  return true;
}

function objectEach (obj, func = _noop) {
  return Object.getOwnPropertyNames(obj)
    .reduce((o, k) => ({ ...o, [k]: func(obj[k]) }), {});
}
