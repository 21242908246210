import { gql }          from '@apollo/client';
// import {graphql} from '../client';

export const GQL_QUERY_GET_FACULTY = gql`
  query getFaculty ($id: ID!) {
    getFaculty(id: $id) {
      id

      lastName
      firstName
      ucinetid

      createdAt
      updatedAt
    }
  }
`;


export const GQL_QUERY_LIST_FACULTY = gql`
  query listFaculty ($filter: FacultyFilterInput, $sort: SortInput, $page: PaginationInput) {
    listFaculty(filter: $filter, sort: $sort, page: $page) {
      items {
        id

        lastName
        firstName
        ucinetid

        createdAt
        updatedAt
      }
      meta {
        count
        pages
      }
    }
  }
`;
