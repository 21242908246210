import { styled }                from '@mui/material';
import Box                       from '@mui/material/Box';
import MuiContainer              from '@mui/material/Container';
import { LayoutContextProvider } from './context';
import Copyright                 from './Copyright';
import MenuBar                   from './MenuBar';

export { useLayout, LayoutContext } from './context';

const Container = styled(MuiContainer)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    paddingLeft:        0,
    paddingRight:       0,

    '& > .MuiPaper-root': {
      borderTopRightRadius: 0,
      borderTopLeftRadius:  0,
    }
  },
}))

export default function Layout ({ nav, lowerNav, children }) {

  return (
    <LayoutContextProvider>
      {/*<RootBG>*/}
      <MenuBar nav={nav} />
      {/*<NavDrawer nav={nav} lowerNav={lowerNav} />*/}

      <Container maxWidth="lg">
        {children}

        <Box py={3} mb={5}>
          <Copyright />
        </Box>
      </Container>
      {/*</RootBG>*/}
    </LayoutContextProvider>
  );
}
