import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled }    from '@mui/material';
import Box           from '@mui/material/Box';
import Button        from '@mui/material/Button';
import Typography    from '@mui/material/Typography';

const Li = styled('li')(({ theme }) => ({
  display: 'inline-block'
}));

export default function Copyright () {
  return (
    <>
      <Box component="ul" sx={{ listStyle: 'none' }} textAlign="center" paddingLeft={0}>

        <Li>
          <Button component="a" href="https://github.oit.uci.edu/education/tirf/issues" target='new'>
            Issues&nbsp;
            <OpenInNewIcon fontSize="inherit" />
          </Button>
        </Li>
        <Li>
          <Button component="a" href="https://github.oit.uci.edu/education/tirf/issues/new" target='new'>
            Create an Issue&nbsp;
            <OpenInNewIcon fontSize="inherit" />
          </Button>
        </Li>


      </Box>

      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © UCI School of Education & UC Regents '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>
  );
}
