import { useState }     from 'react';
import { ucFirstWords } from '../../utils/string';


export const useForm    = (
  {
    defaultValues = {},
    rules = {},
    inputOptions = {}
  }
) => {
  const keys                  = Object.getOwnPropertyNames(defaultValues);
  const [ values, setValues ] = useState({ ...defaultValues });
  const [ errors, setErrors ] = useState(keys.reduce((ob, key) => {
    ob[key] = [];
    return ob;
  }, {}));

  // derived
  const hasErrors = Object.getOwnPropertyNames(errors).reduce((arr, key) => [ ...arr, ...errors[key] ], []).length > 0;
  const dirty     = Object.getOwnPropertyNames(values)
    .reduce((d, key) => d || values[key] !== defaultValues[key], false);
  const isValid   = countErrors() === 0;


  const getField = (name) => {
    const defVal     = defaultValues[name] || '';
    const ruleSet    = rules[name] || {};
    const isBool     = ruleSet.isBool;
    let errorMessage = '';


    if (errors[name] && errors[name].length) {
      errorMessage = errors[name].map((txt, i) => <span key={i} style={{ display: 'block' }}>{txt.message}</span>);
    }

    const changeHandler = (e) => {
      const val              = isBool ? e.target.checked : e.target.value;
      let [ newVal, errors ] = parseRules(val, ruleSet, values);

      setErrors(e => ({ ...e, [name]: errors }));
      setValues(v => ({ ...v, [name]: newVal }));
    }

    let required = ruleSet.required;
    if (typeof required === 'function') {
      required = required(values);
    }

    return {
      name,
      // defaultValue: defVal,
      value:    values[name] !== undefined ? values[name] : defVal,
      label:    ucFirstWords(name),
      required: required,

      error:      !!errorMessage,
      helperText: errorMessage,

      onChange: changeHandler,
      onBlur:   changeHandler,

      ...inputOptions
    }
  }

  const handleSubmit   = (onSubmit, onError) => {
    return (e) => {
      if (!hasErrors) {
        return Promise.resolve(onSubmit(values, e));
      } else if (onError) {
        return Promise.resolve(onError(errors, values, e));
      } else {
        return Promise.reject([ errors, values, e ]);
      }
    }
  }
  const setValue       = (name, value) => {
    if (typeof value === 'function') {
      value = value(values[name]);
    }
    // console.log(value);
    setValues(v => ({ ...v, [name]: value }));
  }
  const getValue       = (name, defaultValue = '') => {
    return typeof values[name] === 'undefined'
           ? defaultValue
           : values[name]
  }
  const getValues      = () => {
    return values;
  };
  const checkErrors    = (...fields) => {
    const errors = Object.getOwnPropertyNames(rules || {})
      .reduce((ob, k) => {
        if (fields.length) {
          if (!fields.includes(k)) {
            ob[k] = [];
            return ob;
          }
        }

        const [ _, errs ] = parseRules(values[k], rules[k], values);
        ob[k]             = errs;
        return ob;
      }, {});
    setErrors(errors);
    return errors;
  };
  const clearErrors    = (...fields) => {
    setErrors(errs => fields.reduce((obj, key) => ({ ...obj, [key]: [] }), errs));
  };
  const clearAllErrors = (...fields) => {
    clearErrors(...Object.getOwnPropertyNames(errors));
  };

  function countErrors () {
    return Object.getOwnPropertyNames(rules || {})
      .map(k => parseRules(values[k], rules[k], values))
      .map(([ _, errs ]) => errs)
      .filter(errs => errs.length)
      .reduce((arr, errs) => [ ...arr, ...errs ], [])
      .length
      ;
  }

  const reset = (newValues) => {
    newValues ??= defaultValues;
    setValues(newValues);
    checkErrors();
  };

  // console.log('err count', countErrors())

  return {
    getField,
    setValue,
    getValue,
    getValues,
    errors,
    checkErrors,
    clearErrors,
    clearAllErrors,
    defaultValues,
    hasErrors,
    handleSubmit,
    dirty,
    reset,
    isValid
  };
}


export const httpValidator     = (val) => /^https?:\/\//i.test(val);
httpValidator.optional         = (val) => !val || httpValidator(val);
httpValidator.message          = 'Must begin with http:// or https://';
httpValidator.optional.message = httpValidator.message;



export const parseRules = (value, rules = {}, values) => {
  let errors = [];
  let newVal = value;

  const {
          // String
          trim,
          lowercase,
          uppercase,
          ucFirst,
          ucFirstWords,
          isEmail,
          isPhone,
          // isUrl,

          // Number
          isInt,
          isFloat,
          min,
          max,
          intHardLimit,
          // decimals,

          // List -- recursive
          isList,
          listMin,
          listMax,
          listNoDuplicates,

          // Boolean
          isBool,

          valueAs,
          validate,

          parseAs,
          displayAs,

        } = rules;

  if (parseAs) {
    newVal = parseAs(newVal);
  }

  if (isList) {
    if (!Array.isArray(newVal)) {
      errors.push(new Error('Lists should be an array'));
    }
    if (typeof listMin === 'number' && newVal.length < listMin) {
      errors.push(new Error(`Must have at least ${listMin} members`));
    }
    if (typeof listMax === 'number' && newVal.length > listMax) {
      errors.push(new Error(`Must have no more than ${listMax} members`));
    }

    const {
            isList:           _1,
            listMin:          _2,
            listMax:          _3,
            listNoDuplicates: _4,
            ...               childRules
          } = rules;

    return newVal
      .map(v => parseRules(v, childRules, values))  // recurse through values
      .reduce(([ newV, errs, v ], [ nextNewV, nextErrs, nextV ]) => {
        if (listNoDuplicates) {
          if (newV.includes(nextNewV)) {
            nextErrs.push(new Error('No duplicate values allowed'))
          }
        }

        // recombine in new arrays
        return [
          [ ...newV, nextNewV ],
          [ ...errs, ...nextErrs ],
          [ ...v, nextV ],
        ]
      }, [ [], [], errors ]);
  }

  if (isInt) {
    let num = parseInt(newVal === '' ? 0 : newVal);
    if (isNaN(num)) {
      errors.push(new Error('Value must be whole number'));
    } else {
      newVal = num;
    }

    if (min !== undefined && newVal < min) {
      if (intHardLimit) {
        newVal = min;
      } else {
        errors.push(new Error('Value must be greater than or equal to ' + min));
      }
    }
    if (max !== undefined && newVal > max) {
      if (intHardLimit) {
        newVal = max;
      } else {
        errors.push(new Error('Value must be less than or equal to ' + max));
      }
    }

  } else if (isFloat) {
    let num = parseFloat(newVal === '' ? 0 : newVal);
    if (isNaN(num)) {
      errors.push(new Error('Value must be number'));
    } else {
      newVal = num;
    }

    if (min !== undefined && newVal < min) {
      errors.push(new Error('Value must be greater than or equal to ' + min));
    }
    if (max !== undefined && newVal > max) {
      errors.push(new Error('Value must be less than or equal to ' + max));
    }

  } else if (isBool) {
    newVal = !!newVal;
  } else { // isString

    if (lowercase) {
      newVal = String.prototype.toLowerCase.call(newVal);
    } else if (uppercase) {
      newVal = String.prototype.toUpperCase.call(newVal);
    } else if (ucFirst) {
      newVal = ucFirst(newVal);
    } else if (ucFirstWords) {
      newVal = ucFirstWords(newVal);
    }

    // if (isUrl && !/^https?:\/\//.test(newVal)) {
    //   errors.push(new Error('URLs must begin with http(s)://'));
    // }

    if (isEmail && !/^.+@.+\..+$/.test(newVal)) {
      errors.push(new Error('Not a valid email address'));
    }
    if (isPhone && !/^(\+?\d+[ -]?)?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/.test(newVal)) {
      errors.push(new Error('Not a valid phone number'));
    }

    if (!Array.isArray(newVal) && (newVal !== undefined && newVal !== null) && (trim === undefined || trim)) {
      // try {
      newVal = String.prototype.trim.call(newVal);
      // } catch (err) {
      //   debugger;
      // }
    }

  }

  if (validate) {
    if (typeof validate === 'function') {
      if (!validate(newVal)) {
        errors.push(new Error(validate.message || 'failed to pass validation.'));
      }
    } else {
      throw new Error('validate rule must be a callable function')
    }
  }

  if (valueAs && !!newVal) {
    newVal = valueAs;
  }

  let required = rules.required;
  if (typeof required === 'function') {
    required = required(values);
  }

  if (required && (!newVal && newVal !== 0)) {
    errors.push(new Error('Field is Required'));
  }


  if (displayAs) {
    newVal = displayAs(newVal)
  }

  return [ newVal, errors, value ];
}
