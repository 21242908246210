import { useQuery }                                                   from '@apollo/client';
import CommentIcon                                                    from '@mui/icons-material/Comment';
import EditIcon                                                       from '@mui/icons-material/Edit';
import LinkIcon                                                       from '@mui/icons-material/Link';
import MoreVertIcon                                                   from '@mui/icons-material/MoreVert';
import {
  ListItem,
  ListItemText,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
}                                                                     from '@mui/material';
import Box                                                            from '@mui/material/Box';
import Button                                                         from '@mui/material/Button';
import Divider                                                        from '@mui/material/Divider';
import Grid                                                           from '@mui/material/Grid';
import IconButton                                                     from '@mui/material/IconButton';
import Link                                                           from '@mui/material/Link';
import List                                                           from '@mui/material/List';
import Paper                                                          from '@mui/material/Paper';
import Typography                                                     from '@mui/material/Typography';
import formatDate                                                     from 'date-fns/format';
import { useEffect }                                                  from 'react';
import { useParams }                                                  from 'react-router-dom';
import { useAuth }                                                    from '../Context/auth';
import { graphql }                                                    from '../data/client';
import { FormDialog, FormRow, TextField, useDialogCtrl, useForm, }    from '../lib/Form';
import { MoreActionMenu }                                             from '../lib/menuElements';
import useSubmitSnack                                                 from '../lib/snacks';
import usePagination                                                  from '../lib/usePagination';
import { ErrorBlurb }                                                 from '../other-pages/Error';
import quarterSort                                                    from '../utils/quarterSort';
import { GQL_MUTATION_UPDATE_STUDENT }                                from './gql/mutations';
import { GQL_QUERY_GET_STUDENT_ALL, GQL_QUERY_LIST_STUDENTS_SUPPORT } from './gql/queries';


export default function GetStudent () {
  const { id }                      = useParams();
  const { sort, page, filter, set } = usePagination({
    filter: { student: id }
  });
  const {
          data,
          loading,
          error,
          refetch: refetchMain
        }                           = useQuery(GQL_QUERY_GET_STUDENT_ALL, { variables: { id } });
  const {
          data:    supportData,
          loading: supportLoading,
          error:   supportError,
          refetch: refetchSupport
        }                           = useQuery(GQL_QUERY_LIST_STUDENTS_SUPPORT, {
    variables: {
      id, sort, page, student: filter.student_text
    }
  });
  const snack                       = useSubmitSnack();
  const editDialogCtrl              = useDialogCtrl();
  const { hasPermissions }          = useAuth();
  const hasPerms                    = hasPermissions([ 'studentAffairs' ])



  const updateStudent = (input) => {
    snack.start('Saving Student Member changes');
    graphql({
      mutation:  GQL_MUTATION_UPDATE_STUDENT,
      variables: { input }
    })
      .then(() => {
        snack.success('Saved Student Member changes')
        refetchMain();
      })
      .catch(snack.catchError)
    ;
  }

  useEffect(() => {
    refetchSupport({ page, sort, id, student: filter.student_text })
  }, [ page, sort, filter, id ])


  let bodyInfo;
  if (error) {
    bodyInfo = <ErrorBlurb err={error} />;
  } else if (loading) {
    bodyInfo = <GetStudentSkeleton />;
  } else if (data) {
    bodyInfo =
      <GetStudentBody student={data.getStudent} onEdit={() => editDialogCtrl.openDialog()} canEdit={hasPerms} />;
  }


  let bodySupport;
  if (supportError) {
    bodySupport = <ErrorBlurb err={supportError} />;
  } else if (supportLoading) {
    bodySupport = <GetStudentSupportSkeleton />;
  } else if (supportData) {
    bodySupport =
      <GetStudentSupportBody support={supportData.listSupport.items} />;
  }

  const pagination = (
    <Box textAlign="center">
      {/*<Pagination*/}
      {/*  count={supportData ? supportData.listSupport.meta.pages : 3}*/}
      {/*  page={page.page}*/}
      {/*  sx={{ display: 'inline-block' }}*/}
      {/*  onChange={(e, newPage) => set('page', { ...page, page: newPage })}*/}
      {/*/>*/}
    </Box>
  );


  return (
    <Paper sx={{ p: 2 }}>

      {bodyInfo}

      <Divider />

      <Box display="flex" alignItems="center">
        <Box width={200}>
          <TextField
            fullWidth size="small"
            variant={'standard'}
            name="filter"
            label="Filter"
            value={filter.text}
            onChange={e => set('filter', { ...filter, student_text: e.target.value })}
          />
        </Box>
        <Box flexGrow={12}>
          {pagination}
        </Box>
        <Box width={200}>
        </Box>
      </Box>

      {bodySupport}

      {editDialogCtrl.open && data && data.getStudent && (
        <EditStudentDialog
          open={editDialogCtrl.open}
          onClose={() => editDialogCtrl.closeDialog()}
          onSubmit={(data) => {
            updateStudent(data);
            editDialogCtrl.closeDialog()
          }}
          student={data.getStudent}
        />
      )}

    </Paper>
  )

}


function GetStudentBody ({ student: stu, onEdit, canEdit = false }) {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">
            {stu.lastName}, {stu.firstName}
          </Typography>
          <Typography variant="subtitle1">
            {stu.ucinetid}@uci.edu
          </Typography>
        </Box>

        <Box paddingX={2}>
          <MoreActionMenu actions={[
            {
              icon:     <EditIcon />,
              text:     'Edit Student Member',
              onClick:  onEdit,
              disabled: !canEdit
            }
          ]}>
            <IconButton edge="end" aria-label="More Actions">
              <MoreVertIcon />
            </IconButton>
          </MoreActionMenu>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Grid container>

        <Grid item sm={6}>

          <List>
            <ListItem>
              <ListItemText primary={stu.firstName} secondary="First Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={stu.lastName} secondary="Last Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={stu.ucinetid} secondary="UCINetID" />
            </ListItem>
            {stu.initialCredit ? (
              <ListItem>
                <ListItemText primary={stu.initialCredit} secondary="Initial TIRF" />
              </ListItem>
            ) : null}
            <ListItem>
              <ListItemText primary={`${stu.ucinetid}@uci.edu`} secondary="Email Address" />
            </ListItem>
          </List>

        </Grid>

      </Grid>
    </>
  );
}

function GetStudentSupportBody ({ support: _support }) {

  const support = [ ..._support ].sort(quarterSort(-1));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell>Funding Source</TableCell>
            <TableCell>Quarter</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right" width={100}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {support.map(s => (
            <TableRow key={s.id}>
              <TableCell>{s.faculty.lastName}, {s.faculty.firstName}</TableCell>
              <TableCell>{s.fundingSource}</TableCell>
              <TableCell>{s.quarter}</TableCell>
              <TableCell align="right">{formatDate(s.createdAt, 'MM/dd/yyyy hh:mm aa')}</TableCell>
              <TableCell align="right" width={100}>
                {s.notes ? (
                  <Tooltip title={s.notes} placement="left">
                    <CommentIcon fontSize="small" />
                  </Tooltip>
                ) : null}
                <Link href={`/support/${s.id}`} sx={{ ml: 1 }}>
                  <LinkIcon />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function GetStudentSkeleton () {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Typography variant="subtitle1">
            <Skeleton width="35%" />
          </Typography>
        </Box>

        <Skeleton variant="circular" height={40} width={40} />
        <Box paddingX={2} color="gray">
          <IconButton edge="end" aria-label="More Actions">
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Grid container>

        <Grid item xs={12} sm={6}>

          <List>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="First Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="Last Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="UCINetID Name" />
            </ListItem>
            {/*<ListItem>*/}
            {/*  <ListItemText primary={<Skeleton />} secondary="Initial TIRF" />*/}
            {/*</ListItem>*/}
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="Email Address" />
            </ListItem>
          </List>

        </Grid>

      </Grid>
    </>
  )
}

function GetStudentSupportSkeleton () {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell>Funding Source</TableCell>
            <TableCell>Quarter</TableCell>
            <TableCell align="right">Created</TableCell>
            <TableCell align="right" width={100}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.from(new Array(4)).map((_, i) => (
            <TableRow key={i}>
              <TableCell><Skeleton width={100} /></TableCell>
              <TableCell><Skeleton width={100} /></TableCell>
              <TableCell><Skeleton width={100} /></TableCell>
              <TableCell align="right"><Skeleton width={100} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function EditStudentDialog (
  {
    open,
    onClose:  _onClose,
    onSubmit: _onSubmit,
    student
  }
) {
  const { getField, getValue, reset, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: {
      id:        student.id,
      ucinetid:  student.ucinetid,
      lastName:  student.lastName,
      firstName: student.firstName,
    },
    rules:         {
      id:        { required: true },
      ucinetid:  { lowercase: true, required: true },
      lastName:  { trim: false, required: true },
      firstName: { trim: false, required: true },
    },
  });
  const canSubmit                                                       = isValid && !hasErrors;

  const onClose = () => {
    reset();
    _onClose();
  }

  const onSubmit = handleSubmit(_onSubmit);

  return (
    <FormDialog
      title={`Edit ${student.lastName}, ${student.firstName}`}
      open={open}
      onClose={onClose}
      actions={[
        <Button key="cancelButton" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submitButton" variant="contained" onClick={onSubmit} disabled={!canSubmit}>
          Save Changes
        </Button>
      ]}
      onReturn={onSubmit}
    >
      <FormRow>
        <TextField fullWidth {...getField('id')} label="ID" disabled />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('ucinetid')} label="UCINetID" disabled />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('firstName')} label="First Name" />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('lastName')} label="Last Name" />
      </FormRow>

    </FormDialog>
  )

}






