import { useQuery }                                  from '@apollo/client';
import LinkIcon                                      from '@mui/icons-material/Link';
import { Skeleton }                                  from '@mui/material';
import Box                                           from '@mui/material/Box';
import Container                                     from '@mui/material/Container';
import Divider                                       from '@mui/material/Divider';
import IconButton                                    from '@mui/material/IconButton';
import Link                                          from '@mui/material/Link';
import Paper                                         from '@mui/material/Paper';
import Typography                                    from '@mui/material/Typography';
import { useMemo }                                   from 'react';
import { useAuth }                                   from '../Context/auth';
import { graphql }                                   from '../data/client';
import { Autocomplete, FormRow, TextField, useForm } from '../lib/Form';
import { MenuButtonGroup }                           from '../lib/menuElements';
import { ErrorBlurb }                                from '../other-pages/Error';
import { nextQuarter, quartersList }                 from '../utils/quartersList';
import {
  AddFacultyDialog,
  AddFacultyProjectDialog,
  AddStudentDialog,
  useSupportDialogs
}                                                    from './components/CreateSupportDialogs';
import PersonAutocomplete                            from './components/PersonAutocompete';
import { GQL_MUTATION_CREATE_SUPPORT }               from './gql/mutations';
import { GQL_QUERY_GET_CREATE_SUPPORT_DATA }         from './gql/queries';

export default function CreateSupport () {
  const { data, loading, error, refetch } = useQuery(GQL_QUERY_GET_CREATE_SUPPORT_DATA);
  const {
          getField,
          setValue,
          getValue,
          hasErrors,
          handleSubmit,
          isValid,
          reset,
          clearAllErrors,
        }                                 = useForm({
    defaultValues: {
      faculty:       null,
      student:       null,
      quarter:       nextQuarter,
      fundingSource: null,
      notes:         '',
    },
    rules:         {
      faculty:       { required: true },
      student:       { required: true },
      quarter:       { required: true },
      fundingSource: { trim: false, required: true },
      notes:         { trim: false },
    }
  });
  const { hasPermissions }                = useAuth();
  const hasPerms                          = hasPermissions([ 'studentAffairs' ])
  const canSubmit                         = isValid && !hasErrors;

  const {
          addFacultyProjectCtrl,
          addFacultyProject,
          addFacultyCtrl,
          addFaculty,
          addStudentCtrl,
          addStudent,
          snack
        } = useSupportDialogs({
    refetch,
    getFaculty: () => selectedFaculty,
    setValue
  })


  const _selectedFacId  = getValue('faculty');
  const selectedFaculty = useMemo(() => {
    const fid = getValue('faculty');
    return fid ? data.faculty.items.find(({ id }) => id === fid) : null;
  }, [ data, _selectedFacId ])

  // console.log(getValues())
  const availableProjects = useMemo(() => {
    return [
      'TIRF',
      'GSR',
      ...(selectedFaculty ? selectedFaculty.projects : []),
    ]
  }, [ selectedFaculty ])

  const createSupport = (doReset = true) => {
    return (data) => {
      snack.start('Creating Support')
      return graphql({
        mutation:  GQL_MUTATION_CREATE_SUPPORT,
        variables: { input: data }
      })
        .then(({ data }) => {
          const sup = data.createSupport;
          snack.success('Support Created', {
            action: (
                      <IconButton component={Link} href={`/student/${sup.id}`}>
                        <LinkIcon />
                      </IconButton>
                    )
          });
          if (doReset) {
            reset();
            clearAllErrors();
          }
        })
        .catch(snack.catchError());
    }
  }


  if (error) {
    return <ErrorBlurb err={error} paper />;
  }

  const resetFundingSource = () => {
    setValue('fundingSource', (fs) => [ 'TIRF', 'GSR' ].includes(fs) ? fs : null);
  }

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 2 }}>

        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4" onClick={() => setValue('faculty', '6267132115535c31e598789d')}>
              Add Support
            </Typography>
          </Box>

          {/*<Box paddingX={2}>*/}
          {/*  <MoreActionMenu actions={[*/}
          {/*    {*/}
          {/*      icon:    <EditIcon />,*/}
          {/*      text:    'Edit Support',*/}
          {/*      onClick: noop*/}
          {/*    }*/}
          {/*  ]}>*/}
          {/*    <IconButton edge="end" aria-label="More Actions">*/}
          {/*      <MoreVertIcon />*/}
          {/*    </IconButton>*/}
          {/*  </MoreActionMenu>*/}
          {/*</Box>*/}
        </Box>
        <Divider sx={{ my: 1 }} />


        <FormRow sx={{ py: 1 }}>
          {loading ? <Skeleton width="100%" /> : (
            <PersonAutocomplete
              {...getField('faculty')}

              label={'Faculty Member'}
              options={data.faculty.items}
              onUpdate={resetFundingSource}
              allowCreate
              onCreate={() => addFacultyCtrl.openDialog()}

              fullWidth
            />
          )}
        </FormRow>
        <FormRow sx={{ py: 1 }}>
          {loading ? <Skeleton width="100%" /> : (
            <PersonAutocomplete
              {...getField('student')}

              label={'Student'}
              options={data.students.items}
              allowCreate
              onCreate={() => addStudentCtrl.openDialog()}

              fullWidth
            />

          )}
        </FormRow>
        <FormRow sx={{ py: 1 }}>
          {loading ? <Skeleton width="100%" /> : (
            <Autocomplete
              {...getField('quarter')}
              options={quartersList}
              fullWidth
              allowCreate
            />
          )}
        </FormRow>

        <Divider />

        <FormRow sx={{ py: 1 }}>
          {loading ? <Skeleton width="100%" /> : (
            <Autocomplete
              {...getField('fundingSource')}
              label="Job/Project"
              disabled={!selectedFaculty}
              options={availableProjects}

              allowCreate
              onCreate={(value) => addFacultyProjectCtrl.openDialog({ initText: value.inputValue })}

              fullWidth
            />
          )}
        </FormRow>

        <Divider />

        <FormRow sx={{ py: 1 }}>
          <TextField
            {...getField('notes')}
            multiline
            fullWidth
            minRows={3}
          />
        </FormRow>

        <Box display="flex" justifyContent="center">
          <MenuButtonGroup
            text="Submit and Reset"
            onClick={handleSubmit(createSupport())}
            disabled={!canSubmit || !hasPerms}
            // size = 'large'
            // disableElevation = false
            // variant = 'contained'
            // mainButtonProps = {}
            // menuButtonProps = {}
            // preventDisableColorChange = false
            // sx = {}
            // anchorOrigin = {}
            // transformOrigin = {}
            options={[
              {
                text:     'Submit w/o Reset',
                disabled: !canSubmit || !hasPerms,
                onClick:  handleSubmit(createSupport(false))
              },
              {
                text: 'Reset', onClick: () => {
                  reset();
                  clearAllErrors()
                }
              }
            ]}
          />
        </Box>
        {hasPerms ? null : (
          <Box textAlign="center">
            <Typography variant="caption" color='warning.main'>
              You don't have permissions to submit new support
            </Typography>
          </Box>
        )}

        {addFacultyCtrl.open && (
          <AddFacultyDialog
            open={addFacultyCtrl.open}
            onClose={() => addFacultyCtrl.closeDialog()}
            onSubmit={(data) => {
              addFaculty(data);
              addFacultyCtrl.closeDialog()
            }}
          />
        )}

        {addStudentCtrl.open && (
          <AddStudentDialog
            open={addStudentCtrl.open}
            onClose={() => addStudentCtrl.closeDialog()}
            onSubmit={(data) => {
              addStudent(data);
              addStudentCtrl.closeDialog()
            }}
          />
        )}

        {addFacultyProjectCtrl.open && (
          <AddFacultyProjectDialog
            open={addFacultyProjectCtrl.open}
            onClose={() => addFacultyProjectCtrl.closeDialog()}
            onSubmit={(data) => {
              addFacultyProject(data);
              addFacultyProjectCtrl.closeDialog()
            }}
            initText={addFacultyProjectCtrl.initText}
          />
        )}

      </Paper>
    </Container>
  )
}

