import { useQuery }                                                                          from '@apollo/client';
import EditIcon
                                                                                             from '@mui/icons-material/Edit';
import LinkIcon
                                                                                             from '@mui/icons-material/Link';
import MoreVertIcon
                                                                                             from '@mui/icons-material/MoreVert';
import {
  DialogContentText,
  Hidden,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField
} from '@mui/material';
import Box                                                                                   from '@mui/material/Box';
import Button
                                                                                             from '@mui/material/Button';
import Divider
                                                                                             from '@mui/material/Divider';
import Grid                                                                                  from '@mui/material/Grid';
import IconButton
                                                                                             from '@mui/material/IconButton';
import Link                                                                                  from '@mui/material/Link';
import List                                                                                  from '@mui/material/List';
import Paper                                                                                 from '@mui/material/Paper';
import Typography
                                  from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth }                from '../Context/auth';
import { graphql }                                                                           from '../data/client';
import { GQL_MUTATION_DELETE_SUPPORT, GQL_MUTATION_UPDATE_SUPPORT, GQL_QUERY_GET_SUPPORT }   from '../data/support';
import { FormDialog, FormRow, useDialogCtrl, useForm }                                       from '../lib/Form';
import { MoreActionMenu }                                                                    from '../lib/menuElements';
import useSubmitSnack                                                                        from '../lib/snacks';
import {
  ErrorBlurb
}                                                                                            from '../other-pages/Error';


export default function GetSupport () {
  const { id }             = useParams();
  const {
          data,
          loading,
          error,
          refetch
        }                  = useQuery(GQL_QUERY_GET_SUPPORT, { variables: { id } });
  const snack              = useSubmitSnack();
  const editDialogCtrl     = useDialogCtrl();
  const { hasPermissions } = useAuth();
  const hasPerms           = hasPermissions([ 'studentAffairs', 'businessOffice' ])

  let bodyInfo;
  if (error) {
    bodyInfo = <ErrorBlurb err={error} />;
  } else if (loading) {
    bodyInfo = <GetSupportSkeleton />;
  } else if (data) {
    bodyInfo =
      <GetSupportBody support={data.getSupport} openEdit={() => editDialogCtrl.openDialog()} canEdit={hasPerms} />;
  }

  const updateSupport = (input) => {

    snack.start('Saving Support changes');
    graphql({
      mutation:  GQL_MUTATION_UPDATE_SUPPORT,
      variables: { input }
    })
      .then(() => {
        snack.success('Saved Support changes')
        refetch();
      })
      .catch(snack.catchError)
    ;
  }

  return (
    <Paper sx={{ p: 2 }}>

      {bodyInfo}

      {editDialogCtrl.open && data && data.getSupport && (
        <EditSupportDialog
          open={editDialogCtrl.open}
          onClose={() => editDialogCtrl.closeDialog()}
          onSubmit={(data) => {
            updateSupport(data);
            editDialogCtrl.closeDialog()
          }}
          support={data.getSupport}
        />
      )}



    </Paper>
  )

}


function GetSupportBody ({ support: sup, openEdit, canEdit = false }) {
  return (

    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">
            Support
          </Typography>
          <Typography variant="subtitle1">
            {sup.quarter}
          </Typography>
        </Box>

        <Box paddingX={2}>
          <MoreActionMenu actions={[
            {
              icon:     <EditIcon />,
              text:     'Edit Support',
              onClick:  openEdit,
              disabled: !canEdit
            }
          ]}>
            <IconButton edge="end" aria-label="More Actions">
              <MoreVertIcon />
            </IconButton>
          </MoreActionMenu>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Grid container>

        <Grid item xs={12} sm={6}>

          <List>
            <ListItemButton component={Link} href={`/faculty/${sup.faculty.id}`}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={`${sup.faculty.lastName}, ${sup.faculty.firstName}`} secondary="Faculty Member" />
            </ListItemButton>
            <ListItemButton component={Link} href={`/student/${sup.student.id}`}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={`${sup.student.lastName}, ${sup.student.firstName}`} secondary="Student" />
            </ListItemButton>
            <ListItem>
              <ListItemText primary={sup.quarter} secondary="Quarter" />
            </ListItem>
            <ListItem>
              <ListItemText primary={sup.fundingSource} secondary="Employment/Project" />
            </ListItem>
          </List>

        </Grid>


        <Grid item sx={{ px: 2, py: { xs: 0, sm: 2 } }} xs={12} sm={6}>
          <Hidden smUp>
            <Divider sx={{ mb: 2 }} />
          </Hidden>

          <Typography variant="subtitle1">
            Notes
          </Typography>
          <TextField
            multiline
            fullWidth
            disabled
            minRows={4}
            value={sup.notes}
            placeholder="(no notes yet)"
          />

        </Grid>

      </Grid>
    </>
  );
}

function GetSupportSkeleton () {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h4">
            Support
          </Typography>
          <Typography variant="subtitle1">
            <Skeleton width="100px" />
          </Typography>
        </Box>

        <Box paddingX={2} color="gray">
          <IconButton edge="end" aria-label="More Actions">
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Grid container>

        <Grid item sm={6}>

          <List sx={{ width: '300px' }}>
            <ListItem>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={<Skeleton />} secondary="Faculty Member" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary={<Skeleton />} secondary="Student" />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="Quarter" />
            </ListItem>
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary="Employment/Project" />
            </ListItem>
          </List>

        </Grid>

        <Hidden smUp>
          <Divider />
        </Hidden>

        <Grid item sx={{ p: 2 }} sm={6}>

          <Typography variant="subtitle1">
            Notes
          </Typography>
          <Typography paragraph>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Typography>
          <Typography paragraph>
            <Skeleton />
            <Skeleton />
          </Typography>
        </Grid>

      </Grid>
    </>
  )
}

function EditSupportDialog (
  {
    open,
    onClose:  _onClose,
    onSubmit: _onSubmit,
    support
  }
) {
  const { getField, getValue, reset, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: {
      id:            support.id,
      fundingSource: support.fundingSource,
      notes:         support.notes,
    },
    rules:         {
      id:            { required: true },
      fundingSource: { trim: false, required: true },
      notes:         { trim: false },
    },
  });

  const deleteDialog = useDialogCtrl();
  const snack = useSubmitSnack();
  const navigate = useNavigate();
  const canSubmit = isValid && !hasErrors;
  const onSubmit  = handleSubmit(_onSubmit);

  const onClose = () => {
    reset();
    _onClose();
  }



  const openDeleteDialog = () => {
    const onDelete = () => {
      deleteDialog.closeDialog();
      onDeleteRole();
    }

    deleteDialog.openDialog({
      title:    'Remove Support Record?',
      actions:  [
        [
          <Button key={0} onClick={onDelete} variant="outlined">
            Remove Support Record
          </Button>,
          <Button key={1} autoFocus onClick={deleteDialog.closeDialog} variant="contained">
            Cancel
          </Button>
        ]
      ],
      children: (
                  <DialogContentText>
                    <Typography variant={'body1'} paragraph>
                      You are about to delete a support record.
                    </Typography>
                    <Typography variant={'body1'} paragraph>
                      Please confirm.
                    </Typography>
                  </DialogContentText>
                )
    })
  };

  const onDeleteRole = () => {
    snack.start('Removing Support Record');

    graphql({
      mutation:  GQL_MUTATION_DELETE_SUPPORT,
      variables: { id: support.id }
    })
      .then(() => {
        snack.success('Removed Support Record')
        _onSubmit();
        onClose();
        navigate(`/faculty/${support.faculty.id}`);
      })
      .catch(snack.catchError('Error removing role.'))
  };


  return (
    <FormDialog
      title={`Edit Support`}
      open={open}
      onClose={onClose}
      actions={[
        <Box key={'deleteButton'} flexGrow={1}>
          <Button key="cancelButton" color={'warning'} onClick={openDeleteDialog}>
            Delete
          </Button>
        </Box>,
        <Button key="cancelButton" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submitButton" variant="contained" onClick={onSubmit} disabled={!canSubmit}>
          Save Changes
        </Button>
      ]}
      onReturn={onSubmit}
    >
      <FormRow>
        <TextField fullWidth {...getField('id')} label="ID" disabled />
      </FormRow>
      <FormRow>
        <TextField fullWidth {...getField('fundingSource')} label="Funding Source" />
      </FormRow>

      <FormRow>
        <TextField
          multiline minRows={4}
          fullWidth
          {...getField('notes')}
        />
      </FormRow>

      {deleteDialog.open && deleteDialog.getDialog()}

    </FormDialog>
  )

}




