const _GQL_ENDPOINTS = {
  dev:        'http://localhost:4000',
  production: 'https://api.tirf.educ.aws.uci.edu',
};

export const environment = process.env.REACT_APP_STAGE || 'dev';
export const apiServer = _GQL_ENDPOINTS[environment];
export const dataEndpoint = apiServer;


const _SAML_ENDPOINTS = {
  // dev:        'http://localhost:3000',
  // dev:    'https://test.saml.educ.aws.uci.edu',
  dev: 'https://saml.educ.aws.uci.edu',
  production: 'https://saml.educ.aws.uci.edu',
};

export const samlServer = _SAML_ENDPOINTS[environment]



// export const eduPeople = {
//   serviceName: 'tech-inventory',
//   url:    'https://vgphi7ohwvhc5hn7wevv75a4ce.appsync-api.us-west-2.amazonaws.com/graphql',
//   appId:  'z6wyfq7uobc23h5oe7d25pc6t4',
//   region: 'us-west-2',
//   auth:   {
//     apiKey: 'da2-ip7ztv7es5g5tepgh7lgl4r5cu',
//     type:   'API_KEY'
//   },
// }
