import { useQuery }                 from '@apollo/client';
import { GQL_QUERY_REDIRECT_TOKEN } from '../../data/auth';

export function useRedirectToken () {
  const { data, loading, error } = useQuery(GQL_QUERY_REDIRECT_TOKEN, {
    variables: { url: window.location.origin }
  });

  return { redirectToken: data && data.token, loading, error };
}
