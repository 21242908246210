import { useQuery }     from '@apollo/client';
import {
  AddCircle
}                       from '@mui/icons-material';
import CheckIcon        from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import LaunchIcon       from '@mui/icons-material/Launch';
import {
  FormControlLabel,
  ListItemButton,
  ListItemText,
  Skeleton,
  Tooltip
}                       from '@mui/material';
import Box              from '@mui/material/Box';
import Button           from '@mui/material/Button';
import Container        from '@mui/material/Container';
import Divider          from '@mui/material/Divider';
import IconButton       from '@mui/material/IconButton';
import List             from '@mui/material/List';
import Paper            from '@mui/material/Paper';
import Typography       from '@mui/material/Typography';
import {
  useState
}                       from 'react';
import {
  useAuth
}                       from '../Context/auth';
import {
  graphql
}                       from '../data/client';
import {
  GQL_MUTATION_CREATE_USER,
  GQL_MUTATION_DELETE_USER,
  GQL_MUTATION_UPDATE_USER,
  GQL_QUERY_LIST_USERS
}                       from '../data/user';
import {
  FormDialog,
  FormRow,
  Select,
  Switch,
  TextField,
  useDialogCtrl,
  useForm
}                       from '../lib/Form';
import useSubmitSnack   from '../lib/snacks';
import usePagination    from '../lib/usePagination';
import {
  ErrorBlurb
}                       from '../other-pages/Error';
import noop             from '../utils/noop';


export default function ListUsers () {
  const [ selectedUser, setSelectedUser ]            = useState(null);
  const { user: loggedInUser }                       = useAuth();
  const { page, sort, filter, set }                  = usePagination({
    page:             { limit: 10, page: 1 },
    sort:             { field: 'ucinetid', direction: 1 },
    filter:           {},
    syncSearchParams: true,
  });
  const { data, loading, error, refetch, fetchMore } = useQuery(GQL_QUERY_LIST_USERS, {
    variables: {
      page, sort, filter
    }
  });
  const confirmCtrl                                  = useDialogCtrl();
  const snack                                        = useSubmitSnack();

  const createUser        = (user) => {
    snack.start(`Creating User ${user.ucinetid}`);
    graphql({
      mutation:  GQL_MUTATION_CREATE_USER,
      variables: { input: user }
    })
      .then(({ data: { createUser } }) => {
        snack.success('User Add Successfully');
        setSelectedUser(null);
        refetch();
      })
      .catch(snack.catchError())
    ;
  }
  const updateUser        = (user) => {
    if (!user.id) {
      return createUser(user);
    }
    snack.start(`Updating User ${user.ucinetid}`);
    graphql({
      mutation:  GQL_MUTATION_UPDATE_USER,
      variables: { input: user }
    })
      .then(({ data: { updateUser } }) => {
        snack.success('User Updated Successfully');
        setSelectedUser(null);
        refetch();
      })
      .catch(snack.catchError())
    ;
  }
  const confirmDeleteUser = (user) => {
    confirmCtrl.openDialog({
      title:   `Are you sure you want to delete ${user.ucinetid}`,
      actions: [
        <Button key={'confirmButton'} onClick={() => {
          confirmCtrl.closeDialog();
          return deleteUser(user.id)
        }}>Confirm</Button>,
        <Button key={'cancelButton'} onClick={() => confirmCtrl.closeDialog()} autoFocus
                variant={'contained'}>Cancel</Button>
      ]
    })
  }
  const deleteUser        = (id) => {
    snack.start('Deleting User');
    graphql({
      mutation:  GQL_MUTATION_DELETE_USER,
      variables: { id }
    })
      .then(({ data }) => {
        snack.success(`Deleted user ${data.deleteUser.ucinetid}`);
        setSelectedUser(null)
        refetch();
      })
      .catch(snack.catchError())
    ;
  }

  console.log(loggedInUser)

  if (error) {
    return <ErrorBlurb err={error} />;
  }

  const users = !loading && data ? data.listUsers.items : [];

  const handleUserClick = (u) => setSelectedUser((user) => u === user ? null : u)
  // console.log(data)

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 2 }}>
        <Box display="flex">
          <Typography variant="h4" flexGrow={1}>
            Users
          </Typography>

          {loggedInUser.isAdmin ? (
            <Tooltip title="Add New User">
              <IconButton onClick={() => setSelectedUser({})}>
                <AddCircle color="success" />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
        {/*<Divider sx={{ py: 1 }} />*/}

        <List>
          {!loading && users
           ? users.map(u =>
              <UserListItem
                key={u.id}
                user={u}
                setUser={handleUserClick}
                open={selectedUser && selectedUser.id === u.id}
              />
            ).joinElements(<Divider variant={'fullWidth'} />)
           : Array.from(new Array(5)).map((_, i) => (
              <ListItemButton key={i}>
                <ListItemText
                  primary={<Skeleton width={'80%'} />}
                  secondary={<Skeleton width={'50%'} />}
                />
              </ListItemButton>
            ))
          }

        </List>
      </Paper>

      {selectedUser ? (
        <UserDialog
          isAdmin={loggedInUser.isAdmin}
          loggedInUcinetid={loggedInUser.ucinetid}
          user={selectedUser}
          open={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          onSubmit={updateUser}
          onDelete={confirmDeleteUser}
        />
      ) : null}

      {confirmCtrl.getDialog()}

    </Container>
  )
}

function UserDialog (
  {
    isAdmin = false,
    onClose = noop,
    user = null,
    onSubmit: _onSubmit = noop,
    onDelete = noop,
    open = false,
    loggedInUcinetid
  }
) {
  const isNew                                          = user.id === undefined;
  const { getField, getValue, handleSubmit, isValid, } = useForm({
    defaultValues: {
      id:        user.id ?? '',
      ucinetid:  user.ucinetid ?? '',
      firstName: user.firstName ?? '',
      lastName:  user.lastName ?? '',
      isAdmin:   user.isAdmin ?? false,
      type:      user.type ?? 'studentAffairs',
    },
    rules:         {
      id:        { required: !isNew },
      ucinetid:  { required: true },
      firstName: { trim: false, required: true },
      lastName:  { trim: false, required: true },
      type:      { required: true },
      isAdmin:   { isBool: true, required: true },
    }
  })

  const onSubmit = (data) => {
    if (isNew) {
      const { id, ...rest } = data;
      data                  = rest;
    }
    data.canLogin = true;
    _onSubmit(data);
  }

  const deleteButton = isNew ? null : (
    <Box key="leftButtons" flexGrow={1} textAlign="left">
      <Button
        variant="outlined"
        color="error"
        disabled={!isAdmin || loggedInUcinetid === user.ucinetid}
        onClick={() => onDelete(user)}
      >
        Delete
      </Button>
    </Box>
  );


  const actions = isAdmin
                  ? [
      deleteButton,
      <Button key="cancelButton" onClick={onClose}>
        Cancel
      </Button>,
      <Button key="submitButton" variant="contained" onClick={handleSubmit(onSubmit)}>
        Save Changes
      </Button>
    ] : [
      <Button key="closeButton" variant="contained" onClick={onClose}>
        Save Changes
      </Button>
    ]

  return (
    <FormDialog
      title={isNew ? 'Add New User' : `${user.firstName} ${user.lastName}`}
      actions={actions}
      open={open}
      onClose={onClose}
    >
      {isNew ? null : (
        <FormRow>
          <TextField {...getField('id')} label="User ID" fullWidth disabled />
        </FormRow>
      )}
      <FormRow>
        <TextField {...getField('ucinetid')} label="UCINetID" fullWidth disabled={!isAdmin} />
      </FormRow>
      <FormRow>
        <TextField {...getField('firstName')} label="First Name" fullWidth disabled={!isAdmin} />
      </FormRow>
      <FormRow>
        <TextField {...getField('lastName')} label="Last Name" fullWidth disabled={!isAdmin} />
      </FormRow>
      <FormRow>
        <Select
          {...getField('type')}
          label="User Type"
          fullWidth
          disabled={!isAdmin}
          options={[
            { label: 'Student Affairs', value: 'studentAffairs' },
            { label: 'Business Office', value: 'businessOffice' },
            { label: 'Admin', value: 'admin' },
            { label: 'Faculty Member', value: 'faculty' },
          ]}
        />
      </FormRow>
      <FormRow>
        <FormControlLabel
          disabled={!isAdmin}
          control={
            isAdmin
            ? <Switch {...getField('isAdmin')} label="Is Administrator" disabled={!isAdmin} />
            : <Check value={getValue('isAdmin')} />
          }
          label={`User Is${getValue('isAdmin') ? '' : ' Not'} An Admin`}
        />
      </FormRow>
    </FormDialog>
  )



}

function Check ({ value }) {
  const Icon = value
               ? <CheckIcon color="success" />
               : <DoNotDisturbIcon color="error" />;
  return (
    <Box paddingX={2}>
      {Icon}
    </Box>
  );
}

function UserListItem ({ user = null, setUser = noop, open = false }) {

  return (
    <ListItemButton key={user.id} onClick={() => setUser(user)}>
      <ListItemText
        primary={`${user.firstName} ${user.lastName}`}
        secondary={`${user.ucinetid}@uci.edu`}
      />
      <LaunchIcon />
    </ListItemButton>
  )

}
