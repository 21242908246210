import { FormControl, FormHelperText }          from '@mui/material';
import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MuiTextField                             from '@mui/material/TextField';
import { useMemo }                              from 'react';
import { Autocomplete }                         from '../../lib/Form';
import { ucFirstWords }                         from '../../utils/string';

export const personSelectProps = {
  map:                  {
    optionToLabel: (option) => {
      if (option) {
        return option.label ?? `${option.lastName}, ${option.firstName}`
      }
      return null;
    },
    optionToValue: (option) => {
      return option ? option.id : null;
    },
    textToOption:  (options, valText) => {
      return valText ? options.find(i => i.id === valText) : null
    }
  },
  isOptionEqualToValue: (a, b) => {
    // console.log('isOptionEqualToValue', {a, b});
    return (a && b) ? a.id === b.id : false
  },
  filterText:           ({ firstName: f, lastName: l, ucinetid: u }) => `${l}, ${f} ${u}`
};



export default function PersonAutocomplete (
  {
    options = [],
    defaultValue,

    value,
    onChange: _onChange,

    map:
      {
        optionToLabel = personSelectProps.map.optionToLabel,
        optionToValue = personSelectProps.map.optionToValue,
        textToOption = personSelectProps.map.textToOption
      } = {},
    isOptionEqualToValue = personSelectProps.isOptionEqualToValue,
    filterText = personSelectProps.filterText,

    onBlur: _,

    ...muiProps

  }
) {

  const onChange = (e, option) => {
    const value = optionToValue(option);
    _onChange({ target: { value } }, value);
  }

  return (
    <Autocomplete
      value={textToOption(options, value)}
      onChange={onChange}

      options={options}

      getOptionLabel={optionToLabel}
      filterText={filterText}

      {...muiProps}
    />
  );
}

export function _PersonAutocomplete (
  {
    options = [],
    defaultValue,

    value,
    onChange: _onChange,

    name = '',
    label,

    required = false,
    error = false,
    helperText,

    sx = null,
    selectSx = null,
    fullWidth = false,

    map:
      {
        optionToLabel = personSelectProps.map.optionToLabel,
        optionToValue = personSelectProps.map.optionToValue,
        textToOption = personSelectProps.map.textToOption
      } = {},
    isOptionEqualToValue = personSelectProps.isOptionEqualToValue,
    filterText: _filterText = personSelectProps.filterText,

    onBlur: _,

    ...muiProps

  }
) {
  label = label || ucFirstWords(name);
  if (required) {
    label = `${label} *`
  }

  const onChange = (e, value) => {
    _onChange({ target: { value: optionToValue(value) } });
  }

  const filterText = useMemo(() => {
    const config = {}

    if (typeof _filterText === 'function') {
      config.stringify = _filterText;
    }

    return createFilterOptions(config)
  }, [ _filterText ])



  return (
    <FormControl error={error} sx={sx} fullWidth={fullWidth}>
      <MuiAutocomplete
        selectOnFocus
        handleHomeEndKeys

        value={textToOption(options, value)}
        onChange={onChange}

        required={required}
        fullWidth={fullWidth}

        options={options}
        sx={selectSx}
        renderInput={(params) => <MuiTextField {...params} label={label} />}

        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={optionToLabel}
        filterOptions={filterText}

        {...muiProps}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}
