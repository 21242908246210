import { gql } from '@apollo/client';

export const GQL_MUTATION_UPDATE_FACULTY = gql`
  mutation updateFaculty ($input: UpdateFacultyInput!) {
    updateFaculty(input: $input) {
      id

      ucinetid
      lastName
      firstName
      initialCredit
      availableCredit

      projects

      updatedAt
      createdAt
    }
  }
`;
