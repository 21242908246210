import { Route, Routes } from 'react-router-dom';
import { environment }   from './config';
import Context           from './Context';
import { AuthGateway }   from './Context/auth';
import GetFaculty        from './Faculty/GetFaculty';
import ListFaculty       from './Faculty/ListFaculty';
import Layout            from './Layout';
import { Nav }           from './Layout/Nav'
import Login             from './other-pages/Login';
import GetStudent      from './Student/GetStudent';
import ListStudents    from './Student/ListStudents';
import CreateSupport     from './Support/CreateSupport';
import GetSupport      from './Support/GetSupport';
import ListUsers       from './User/ListUsers';

function Noop ({ name = 'Noop Path' }) {
  return name;
}

function App () {
  return (
    <Context>
      <Layout nav={Nav}>
        <AuthGateway unauth={<>hello</>} login={<Login />}>

          <Routes>
            <Route path="/" element={<CreateSupport />} />
            <Route path="/faculty">
              <Route path=":id" element={<GetFaculty />} />
              <Route index element={<ListFaculty />} />
            </Route>

            {environment === 'dev' ? (
              <Route path="/dev" element={<Noop name="Dev" />} />
            ) : null}
            <Route path="/support/:id" element={<GetSupport />} />
            <Route path="/student">
              <Route path=":id" element={<GetStudent />} />
              <Route index element={<ListStudents />} />
            </Route>
            <Route path="/user" element={<ListUsers />} />
          </Routes>

        </AuthGateway>
      </Layout>
    </Context>
  );
}

export default App;
