import { gql } from '@apollo/client';

export const GQL_MUTATION_UPDATE_STUDENT = gql`
  mutation updateStudent ($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      id

      ucinetid
      lastName
      firstName

      updatedAt
      createdAt
    }
  }
`;
