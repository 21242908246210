import styled                  from '@emotion/styled';
import { CircularProgress }    from '@mui/material';
import Button                  from '@mui/material/Button';
import Container               from '@mui/material/Container';
import Paper                   from '@mui/material/Paper';
import Typography              from '@mui/material/Typography';
import { useLocation }         from 'react-router-dom';
import { LOGIN_HREF, useAuth } from '../Context/auth';
import { useRedirectToken }    from '../Context/auth/useRedirectToken';


function getDomain () {
  const l = window.location;
  return `${l.protocol}//${l.host}`;
}

const Background = styled('header')(({ theme }) => ({
  backgroundColor:   theme.palette.primary.main,
  borderBottomWidth: 4,
  borderBottomStyle: 'solid',
  borderColor:       theme.palette.secondary.main,
  marginBottom:      theme.spacing(1)
}));

export default function Login () {
  const location                          = useLocation();
  const { user, token, tokenPayload }     = useAuth();
  const { redirectToken, loading, error } = useRedirectToken();

  // console.log('loginEl', location.state);
  let authMessage = location.state && location.state.authErrorMessage;

  if (!authMessage) {
    if (token) {
      if (!tokenPayload) {
        authMessage = 'Token invalid: Please log in again';
      } else if (!user) {
        authMessage = 'Logged in but no permissions. Please contact administrator to get permissions to access this server.';
      }
    }
  }

  const href = `${LOGIN_HREF}?return-url=${redirectToken}`;

  return (
    <Container maxWidth="sm" sx={{ my: 5, mx: 'auto' }}>
      <Paper elevation={1} sx={{ p: 3 }}>
        <Typography sx={{ mb: 2 }} variant="h4">Login</Typography>

        {authMessage ? (
          <Typography variant={'subtitle1'} paragraph sx={{ color: 'warning.main' }}>{authMessage}</Typography>
        ) : (
           <Typography paragraph>
             This service requires you to be authenticated
           </Typography>
         )}



        <Button variant="contained" disabled={loading || error} fullWidth component="a" href={href}>
          {loading
           ? <CircularProgress size={24.5} />
           : (error
              ? `Problem logging in`
              : `Login${authMessage ? ' again' : ''}`
           )
          }

        </Button>

        {error ? (
          <Typography variant="body2" textAlign="center" sx={{ mt: 2 }}>
            {error.message}<br />
            Contact Administrator.
          </Typography>
        ) : null}

      </Paper>
    </Container>
  );
};
