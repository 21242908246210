import { gql }                      from '@apollo/client';
// import {graphql} from '../client';

export const GQL_MUTATION_CREATE_USER = gql`
  mutation createUser ($input: CreateUserInput!) {
    createUser(input: $input) {
      id

      lastName
      firstName
      ucinetid
      type

      canLogin
      isAdmin
      
      createdAt
      updatedAt
    }
  }
`;

export const GQL_MUTATION_UPDATE_USER = gql`
  mutation updateUser ($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id

      lastName
      firstName
      ucinetid
      type

      canLogin
      isAdmin

      createdAt
      updatedAt
    }
  }
`;

export const GQL_MUTATION_DELETE_USER = gql`
  mutation deleteUser ($id: ID!) {
    deleteUser(id: $id) {
      id

      lastName
      firstName
      ucinetid

      canLogin
      isAdmin

      createdAt
      updatedAt
    }
  }
`;
